import React, { useEffect, useState, Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox } from 'antd';
import css from '../style.module.scss';
import { auth } from '../../../../api/auth';

import eyeCloseIcon from '../../../../assets/icons/eye-close.svg';
import eyeOpenIcon from '../../../../assets/icons/eye-open.svg';

import Button from '../../../Button/Button';

interface SignFormProps {
  setForm?: Dispatch<SetStateAction<string>>;
  openNotification: Function;
}

interface DataState {
  login: string;
  password: string;
}

const SignForm: React.FC<SignFormProps> = ({ setForm, openNotification }) => {
  const { t } = useTranslation();

  const [visible, setVisible] = useState(false);

  const [data, setData] = useState<DataState>({ login: '', password: '' });

  const handleClick = () => {
    const reg = /([A-Za-z0-9_\-\.])\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

    if (!reg.test(data.login)) {
      return openNotification(t('shell:signin.errorEmail'));
    }
    if (data.password === '') {
      return openNotification(t('shell:signin.errorPass'));
    }

    return auth({ ...data });
  };

  return (
    <div className={css.form}>
      <div className={css.title}> {t('shell:signin.signFormTitle')}</div>
      <div className={css.inputWrapper}>
        <input
          type="text"
          placeholder={t('shell:signin.email')}
          value={data.email}
          onChange={e => setData({ ...data, login: e.target.value })}
        />
      </div>
      <div className={css.inputWrapper}>
        <input
          type={!visible ? 'password' : 'text'}
          placeholder={t('shell:signin.password')}
          value={data.pass}
          onChange={e => setData({ ...data, password: e.target.value })}
        />
        <div onClick={() => (visible ? setVisible(false) : setVisible(true))}>
          {visible ? <img src={eyeOpenIcon} alt="icon" /> : <img src={eyeCloseIcon} alt="icon" />}
        </div>
      </div>
      <div className={css.checkbox}>
        <Checkbox>{t('shell:signin.signFormSavePass')}</Checkbox>
      </div>
      <Button title={t('shell:signin.signFormBtn')} border fontWeight={600} onClick={handleClick} />
      <div className={css.footer} onClick={() => setForm('forget')}>
        {t('shell:signin.forgetFormTitle')}
      </div>
    </div>
  );
};

export default SignForm;
