import React from 'react';
import style from './style.module.scss';
import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

const Payment: React.FC = () => {
  const { Text } = Typography;
  const { t } = useTranslation();
  return (
    <div className={style.payment}>
      <div className={style.paymentList}>
        <div className={style.iconWrap}>
          <div className={cx(style.paymenIcon, style.iconVisa)} />
        </div>
        <div className={style.iconWrap}>
          <div className={cx(style.paymenIcon, style.iconMasterCard)} />
        </div>
        <div className={style.iconWrap}>
          <div className={cx(style.paymenIcon, style.iconPaypal)} />
        </div>
      </div>
      <Text className={style.paymentDesign}>{t(`footer:payment.design`)}</Text>
    </div>
  );
};

export default Payment;
