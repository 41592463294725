import React from 'react';
import { ButtonWrapper, StyledButton, Present, Centimeter } from './style';
import style from './style.module.scss';
import { ButtonType, FontWeight, IconsType } from '../../lib/constants/enums';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import cx from 'classnames';
import ColorService from '../../lib/services/colorService';
import { colors } from '../../styles/variables';
import { UserOutlined, AppstoreOutlined } from '@ant-design/icons';

interface ButtonProps {
  title: string;
  border?: boolean;
  width?: number;
  type?: ButtonType;
  fontWeight?: FontWeight;
  uppercase?: boolean;
  fontSize?: number;
  fontColor?: string;
  onClick?: () => void;
  loading?: boolean;
  css?: React.CSSProperties;
  className?: string;
  bgColor?: string;
  shadowColor?: string;
  active?: boolean;
  from?: string;
  to?: string;
  icon?: IconsType;
  after?: any;
}

const Button: React.FC<ButtonProps> = props => {
  const {
    title,
    border,
    width,
    type,
    fontWeight = FontWeight.normal,
    fontSize,
    uppercase,
    onClick,
    loading,
    css,
    className,
    bgColor,
    shadowColor,
    active = false,
    from = colors.yellow,
    to = colors.green,
    fontColor = colors.btngray,
    icon,
    after,
  } = props;
  const colorService = new ColorService();
  const viewImage = type === ButtonType.present ? <Present /> : <Centimeter />;
  const antIcon = <LoadingOutlined className={style.buttonSpin} spin />;

  return (
    <ButtonWrapper className={style.buttonWrap} width={width} border={border} active={active} bgColor={bgColor}>
      {border ? <div className={style.border} style={{ boxShadow: colorService.boxgradient(from, to, 0.5) }} /> : null}
      <StyledButton
        disabled={loading}
        style={css}
        onClick={onClick}
        className={cx(style.button, className)}
        fontWeight={fontWeight}
        fontColor={fontColor}
        uppercase={uppercase}
        fontSize={fontSize}
        active={active}
        bgColor={bgColor}
        shadowColor={shadowColor}
      >
        {!loading && icon === IconsType.user && (
          <div className={style.iconWrap}>
            <UserOutlined />
          </div>
        )}
        {!loading && icon === IconsType.store && (
          <div className={style.iconWrap}>
            <AppstoreOutlined style={{ color: colors.btngray }} />
          </div>
        )}
        {loading && <Spin indicator={antIcon} />}
        {title}
        {type ? viewImage : null}
        {after}
      </StyledButton>
    </ButtonWrapper>
  );
};

export default Button;
