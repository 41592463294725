import React, { useEffect, useState } from 'react';
import css from '../style.module.scss';

interface BreadProps {
  urlArr: string[];
  seo: { Title: string };
}

interface BreadcrumbsArray {
  name: string;
  url: string;
}

const Bread: React.FC<BreadProps> = ({ urlArr, seo }) => {
  const [breadcrumbs, setBreadcrumbs] = useState<Array<BreadcrumbsArray>>([]);

  const mainUrl = 'https://medushkina.com/';

  useEffect(() => {
    const arr = [{ url: mainUrl, name: 'О нас' }];

    if (urlArr[0] === '' && urlArr.length === 1) {
      return setBreadcrumbs(arr);
    }
    if (urlArr.length === 1) {
      arr.push({ name: seo.Title, url: mainUrl + urlArr[0] });
      setBreadcrumbs(arr);
    }
  }, []);

  return (
    <nav className={css.transparent}>
      <ol>
        {breadcrumbs.map((item, index) => {
          return (
            <li key={`${index + 1}BREAD`}>
              <a href={item.url}>{item.name}</a>
            </li>
          );
        })}
      </ol>
    </nav>
  );
};

export default Bread;
