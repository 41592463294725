import React, { useState, Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import css from '../style.module.scss';

import Button from '../../../Button/Button';

interface ForgetFormProps {
  setForm?: Dispatch<SetStateAction<string>>;
  openNotification: Function;
}

const ForgetForm: React.FC<ForgetFormProps> = ({ setForm, openNotification }) => {
  const { t } = useTranslation();

  const [email, setEmail] = useState('');

  const handleClick = () => {
    const reg = /([A-Za-z0-9_\-\.])\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

    return reg.test(email) ? '' : openNotification(t('shell:signin.errorEmail'));
  };

  return (
    <div className={css.form}>
      <div className={css.title}> {t('shell:signin.forgetFormTitle')}</div>
      <div className={css.text}>{t('shell:signin.forgetFormInfo1')}</div>
      <div className={css.text}>
        {t('shell:signin.forgetFormInfo2')}{' '}
        <a href="mailto:goslim.tech@gmail.com" rel="noreferrer" target="_blank">
          goslim.tech@gmail.com
        </a>
      </div>
      <div className={css.inputWrapper}>
        <input type="text" placeholder={t('shell:signin.email')} onChange={e => setEmail(e.target.value)} />
      </div>
      <Button title={t('shell:signin.forgetFormBtn')} border fontWeight={600} onClick={handleClick} />
    </div>
  );
};

export default ForgetForm;
