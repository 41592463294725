import { Instagram, Youtube, Telegram, Facebook, Vkontakte, LogoVisa, LogoPaypal, LogoMastercard, LogoStripe, LogoCash } from '../../Icons';
import { ListIconsDataInterface } from '../../lib/common-interfaces';

export const listIconsData: ListIconsDataInterface = {
  instagram: {
    Icon: Instagram,
    link: 'https://www.instagram.com/katya_medushkina/',
  },
  youtube: {
    Icon: Youtube,
    link: 'https://www.youtube.com/channel/UCBxIz94B_duKCP1OMAD0aPg',
  },
  telegram: {
    Icon: Telegram,
    link: ' https://t.me/s/katya_medushkina_fit',
  },
  facebook: {
    Icon: Facebook,
    link: 'https://www.facebook.com/ekaterina.medushkina',
  },
  vkontakte: {
    Icon: Vkontakte,
    link: 'https://vk.com/katya_medushkina',
  },
};
