import * as React from 'react';
function SvgReviewIcon(props) {
  return (
    <svg width={40} height={64} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M39.863 20.356v-.001C39.041 9.106 30.834.94 20.35.94 9.589.94.834 9.694.834 20.456c0 10.38 8.148 18.895 18.385 19.483.178 1.912.043 7.118-4.95 14.368a1.374 1.374 0 00.16 1.752c2.035 2.034 3.295 3.318 4.178 4.216C19.77 61.46 20.3 62 21.075 62.703a1.378 1.378 0 001.826.017c8.697-7.567 18.359-23.204 16.962-42.364zm-17.85 39.45c-.378-.373-.809-.81-1.444-1.457-.765-.78-1.811-1.845-3.397-3.435 6.057-9.335 4.914-15.982 4.415-16.935a1.429 1.429 0 00-1.237-.758c-9.245 0-16.766-7.52-16.766-16.765 0-9.244 7.52-16.765 16.766-16.765 9.003 0 16.057 7.092 16.771 16.865v-.001c1.57 21.549-11.239 35.544-15.109 39.25z"
        fill="#A4CE41"
      />
      <path
        d="M39.863 20.356v-.001C39.041 9.106 30.834.94 20.35.94 9.589.94.834 9.694.834 20.456c0 10.38 8.148 18.895 18.385 19.483.178 1.912.043 7.118-4.95 14.368a1.374 1.374 0 00.16 1.752c2.035 2.034 3.295 3.318 4.178 4.216C19.77 61.46 20.3 62 21.075 62.703a1.378 1.378 0 001.826.017c8.697-7.567 18.359-23.204 16.962-42.364zm-17.85 39.45c-.378-.373-.809-.81-1.444-1.457-.765-.78-1.811-1.845-3.397-3.435 6.057-9.335 4.914-15.982 4.415-16.935a1.429 1.429 0 00-1.237-.758c-9.245 0-16.766-7.52-16.766-16.765 0-9.244 7.52-16.765 16.766-16.765 9.003 0 16.057 7.092 16.771 16.865v-.001c1.57 21.549-11.239 35.544-15.109 39.25z"
        fill="url(#review-icon_svg__paint0_linear)"
      />
      <defs>
        <linearGradient id="review-icon_svg__paint0_linear" x1={20.71} y1={0.941} x2={20.71} y2={63.059} gradientUnits="userSpaceOnUse">
          <stop stopColor="#D3E321" />
          <stop offset={1} stopColor="#fff" stopOpacity={0} />
        </linearGradient>
      </defs>
    </svg>
  );
}
export default SvgReviewIcon;
