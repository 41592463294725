import * as React from 'react';
function SvgFacebook(props) {
  return (
    <svg width={30} height={29} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M17.676 28.78zM17.963 28.73l-.171.03.171-.03zM16.995 28.873zM17.27 28.84zM18.354 28.652zM19.325 28.412l-.122.034.122-.034zM19.032 28.492l-.134.035a8.69 8.69 0 00.134-.035zM18.647 28.587l-.141.032c.047-.01.095-.02.141-.032zM16.568 28.915z"
        fill="#000"
      />
      <path
        d="M29.724 14.602C29.724 6.67 23.292.238 15.36.238S.996 6.67.996 14.602 7.428 28.966 15.36 28.966l.253-.003V17.78h-3.086v-3.596h3.086v-2.647c0-3.07 1.873-4.74 4.611-4.74 1.311 0 2.438.098 2.767.142v3.207h-1.888c-1.49 0-1.778.708-1.778 1.747v2.29h3.562l-.465 3.597h-3.097v10.63c6.004-1.721 10.399-7.252 10.399-13.809z"
        fill="#A4CE41"
      />
      <path
        d="M29.724 14.602C29.724 6.67 23.292.238 15.36.238S.996 6.67.996 14.602 7.428 28.966 15.36 28.966l.253-.003V17.78h-3.086v-3.596h3.086v-2.647c0-3.07 1.873-4.74 4.611-4.74 1.311 0 2.438.098 2.767.142v3.207h-1.888c-1.49 0-1.778.708-1.778 1.747v2.29h3.562l-.465 3.597h-3.097v10.63c6.004-1.721 10.399-7.252 10.399-13.809z"
        fill="url(#Facebook_svg__paint0_linear)"
      />
      <path d="M16.309 28.934zM15.851 28.957z" fill="#000" />
      <defs>
        <linearGradient id="Facebook_svg__paint0_linear" x1={15.575} y1={0.238} x2={15.575} y2={28.966} gradientUnits="userSpaceOnUse">
          <stop stopColor="#D3E321" />
          <stop offset={1} stopColor="#fff" stopOpacity={0} />
        </linearGradient>
      </defs>
    </svg>
  );
}
export default SvgFacebook;
