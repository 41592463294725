import React from 'react';
import classnames from 'classnames';
import style from './style.module.scss';
import { CircleBgType } from '../../lib/constants/enums';
import { circleBgData } from './data';
import { colors } from '../../styles/variables';
import ColorService from '../../lib/services/colorService';

type position = 'top' | 'bottom' | 'left' | 'right';

interface CircleProps {
  width?: string;
  title?: string;
  bgType?: CircleBgType;
  backgound?: string;
  backgoundWidth?: string;
  backgroundHeight?: string;
  fontSize?: string;
  titlePosition?: position;
  titleOpacity?: number;
  titleColor?: string;
  borderCount?: number;
  borderStep?: number;
  circleColor?: string;
  circleWidth?: string;
  circleHeight?: string;
  from?: string;
  to?: string;
}

const Circle: React.FC<CircleProps> = props => {
  const {
    width = '100%',
    title,
    bgType,
    backgound,
    backgoundWidth,
    backgroundHeight,
    fontSize = '200px',
    titlePosition = 'left',
    titleOpacity = 1,
    titleColor = '#fff',
    borderCount = 2,
    circleColor = colors.circleGradientDefault,
    circleWidth = '415px',
    circleHeight = '415px',
    borderStep = 70,
    from = colors.yellow,
    to = colors.green,
  } = props;
  const colorService = new ColorService();

  const styleCircle = {
    width: circleWidth,
    height: circleHeight,
    background: circleColor,
  };

  const styleTitle = {
    fontSize,
    lineHeight: fontSize,
    opacity: titleOpacity,
    color: titleColor,
  };

  const borderArray = Array.apply(null, { length: borderCount }).map(Number.call, Number);
  return (
    <div style={{ width }} className={style.circleWrapper}>
      <div style={styleCircle} className={style.circle}>
        {borderArray.map((_, index) => {
          const _plus = _ + 1;
          const styleBorder = {
            width: `calc(${circleWidth} + ${_plus * borderStep}px)`,
            height: `calc(${circleHeight} + ${_plus * borderStep}px)`,
            opacity: `${0.47 - _plus / 10}`,
            boxShadow: colorService.boxgradient(from, to, 0.5),
          };
          return <div style={styleBorder} key={index} className={style.border} />;
        })}
        {title && (
          <div style={styleTitle} className={classnames(style.title, style[titlePosition])}>
            {title}
          </div>
        )}
        {bgType && <img className={classnames(style.bgImage, style[`${bgType}Image`])} src={circleBgData[bgType]} alt={bgType} />}
        {backgound && (
          <img
            src={backgound}
            style={{ width: backgoundWidth && backgoundWidth, height: backgroundHeight && backgroundHeight }}
            className={style.background}
          />
        )}
      </div>
    </div>
  );
};

export default Circle;
