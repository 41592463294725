import React from 'react';
import { MenuItem } from '../common-interfaces';
import { StepStatus } from './enums';
import { StrapiBlocksName } from './strapi';
import { Promo } from '../../modules/modules';
import { CardItem } from '../../components/components';

export const menuItems: MenuItem[] = [
  {
    id: '1',
    name: 'home',
    url: '',
  },
  {
    id: '2',
    name: 'about',
    url: 'about',
  },
  {
    id: '3',
    name: 'faq',
    url: 'faq',
  },
  {
    id: '4',
    name: 'warranties',
    url: 'warranties',
  },
  {
    id: '5',
    name: 'shipping',
    url: 'shipping',
  },
];

export const initialStatus = {
  0: StepStatus.wait,
  1: StepStatus.wait,
  2: StepStatus.wait,
};

export const fields = {
  step1: ['firstName', 'lastName', 'email', 'country', 'city', 'address', 'postalCode', 'phone'],
  step2: ['name', 'price'],
  step3: ['name'],
};

export const dataSubfields = {
  step1: 'user',
  step2: 'shippingMethod',
  step3: 'paymentMethod',
};

export const comingSoonUrl = {
  nutrilogy: '/academy-of-nutrition',
  problemzone: '/training-complex'
}

// export const componentsList = [
//   {
//     name: StrapiBlocksName.aboutSplash,
//     component: () => <Promo />,
//   },
// ];
