import gql from 'graphql-tag';

export const OPTIONS_CATEGORY_REQUEST = gql`
  {
    OptionsCategory {
      id
      name {
        en
        ru
      }
      options {
        id
        name {
          en
          ru
        }
      }
    }
  }
`;
