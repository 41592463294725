import gql from 'graphql-tag';

export const GET_COUNTRY_BY_ABV_REQUEST = (countryAbv: string) => {
  return gql`
    {
      CountryAbv(abv: "${countryAbv}") {
        id
      }
    }
  `;
};

export const GET_ALL_COUNTRIES = () => {
  return gql`
    {
      Countries {
        id
        code
        abv3
        abv
        rating
        regionAbv
        slug
        name {
          en
          ru
        }
      }
    }
  `;
};
