import gql from 'graphql-tag';

export const CREATE_INVOICE_REQUEST = gql`
  mutation CreateInvoiceRequest($dto: InvoiceCreateRequestDto!) {
    createInvoice(dto: $dto) {
      uid
    }
  }
`;

export const INVOICE_PAY_REQUEST = gql`
  mutation InvoicePayRequest($dto: InvoicePayGraphqlRequestDto!, $uid: String!) {
    invoicePay(dto: $dto, uid: $uid) {
      paymentUrl
    }
  }
`;
