import React from 'react';
import style from './style.module.scss';

import Copyright from './copyright';
import Politics from './politics';
import Payment from './payment';

const BottomFooterContent: React.FC = () => {
  return (
    <div className={style.footerBottomContent}>
      <Copyright />
      <Politics />
      <Payment />
    </div>
  );
};

export default BottomFooterContent;
