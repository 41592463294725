import { IconKeysInterface } from '../../../../lib/common-interfaces';
import { IconsType } from '../../../../lib/constants/enums';

export const socialData: IconKeysInterface = [
  IconsType.instagram,
  IconsType.youtube,
  IconsType.telegram,
  IconsType.facebook,
  IconsType.vkontakte,
];
