import React, { ReactNode } from 'react';
import style from './style.module.scss';
import Slider from 'react-slick';
import cx from 'classnames';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Layouts } from '../components';

interface SliderProps {
  children: ReactNode;
  className?: string;
}

interface ResponsiveObj {
  breakpoint: number;
  settings: Settings;
}

interface Settings {
  dots?: boolean;
  infinite?: boolean;
  speed?: number;
  slidesToShow?: number;
  slidesToScroll?: number;
  appendDots?: (dots: ReactNode) => JSX.Element;
  customPaging?: (index: number) => JSX.Element;
  responsive?: ResponsiveObj[];
  beforeChange?: (index: number) => void;
  afterChange?: (index: number) => void;
  prevArrow?: JSX.Element;
  nextArrow?: JSX.Element;
}

type Props = SliderProps & Settings;

const SliderComponent: React.FC<Props> = ({
  children,
  className,
  dots = true,
  infinite = true,
  speed = 500,
  slidesToShow = 1,
  slidesToScroll = 1,
  appendDots = dots => (
    <div
      style={{
        bottom: '-60px',
      }}
    >
      <ul> {dots} </ul>
    </div>
  ),
  customPaging = () => <div className={style.sliderDots} />,
  responsive = [
    {
      breakpoint: 764,
      settings: {
        dots: false,
      },
    },
  ],
  beforeChange,
  afterChange,
  prevArrow,
  nextArrow,
}) => {
  const { Container } = Layouts;
  const settings = {
    dots,
    infinite,
    speed,
    slidesToShow,
    slidesToScroll,
    appendDots,
    customPaging,
    responsive,
    beforeChange,
    afterChange,
    prevArrow,
    nextArrow,
  };
  return (
    <Container className={style.container}>
      <Slider className={cx(style.slider, className)} {...settings}>
        {children}
      </Slider>
    </Container>
  );
};

export default SliderComponent;
