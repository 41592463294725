import React, { useEffect, useState, Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, notification } from 'antd';

import css from './style.module.scss';

import closeCircleIcon from '../../../assets/icons/close-circle-btn.svg';
import arrowLeftThinBlack from '../../../assets/icons/arrow-left-thin-black.svg';

import SignForm from './SignForm/SignForm';
import ForgetForm from './ForgetForm/ForgetForm';
import RegForm from './RegForm/RegForm';

interface AuthFormProps {
  auth: { [key: string]: boolean };
  setAuth: Dispatch<SetStateAction<{ [key: string]: boolean }>>;
}

const AuthForm: React.FC<AuthFormProps> = ({ auth, setAuth }) => {
  const { t } = useTranslation();

  const [form, setForm] = useState('sign');

  const openNotification = (text: string) => {
    return notification.open({
      message: t('shell:signin.error'),
      description: text,
    });
  };

  const control = () => {
    switch (form) {
      case 'reg':
        return <RegForm setForm={setForm} openNotification={openNotification} />;
      case 'forget':
        return <ForgetForm setForm={setForm} openNotification={openNotification} />;
      default:
        return <SignForm setForm={setForm} openNotification={openNotification} />;
    }
  };

  return (
    <Modal
      visible={auth.modal}
      afterClose={() => setAuth({ modal: false, render: false })}
      closable={false}
      title={false}
      footer={null}
      width={515}
      bodyStyle={{ backgroundColor: 'rgba(255, 255, 255, 0.9)', borderRadius: '10px' }}
    >
      <div className={css.header}>
        <div className={css.goBackBtn} style={{ display: form === 'forget' ? 'block' : 'none' }} onClick={() => setForm('sign')}>
          <img src={arrowLeftThinBlack} alt="goBack" />
        </div>
        <div style={{ display: form === 'forget' ? 'none' : 'inherit' }}>
          <div onClick={() => setForm('sign')} className={form === 'reg' ? css.selected : ''}>
            {t('shell:signin.signForm')}
          </div>
          <span>|</span>
          <div onClick={() => setForm('reg')} className={form === 'sign' ? css.selected : ''}>
            {t('shell:signin.regForm')}
          </div>
        </div>
        <div className={css.closeBtn} onClick={() => setAuth({ modal: false, render: true })}>
          <img src={closeCircleIcon} alt="close" />
        </div>
      </div>
      {control()}
    </Modal>
  );
};

export default AuthForm;
