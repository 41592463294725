import * as React from 'react';
function SvgMarket(props) {
  return (
    <svg width={18} height={16} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g filter="url(#Market_svg__filter0_i)">
        <path
          d="M17.843 3.189a.748.748 0 00-.582-.276H3.187l-.21-2.26A.73.73 0 002.241 0H.74A.728.728 0 000 .717c0 .396.331.717.74.717h.825l.81 8.755v.03a.7.7 0 00.011.081l.03.32A2.74 2.74 0 003.48 12.49c-.299.37-.477.833-.477 1.337C3.004 15.025 4.009 16 5.245 16c1.236 0 2.241-.975 2.241-2.174 0-.26-.047-.508-.133-.74h4.046c-.087.232-.134.48-.134.74 0 1.199 1.005 2.174 2.24 2.174 1.237 0 2.242-.975 2.242-2.174 0-1.198-1.005-2.173-2.241-2.173H5.25c-.561 0-1.05-.329-1.262-.807l11.814-.674a.734.734 0 00.674-.542l1.502-5.826a.7.7 0 00-.135-.615z"
          fill="#B5DC69"
        />
      </g>
      <defs>
        <filter id="Market_svg__filter0_i" x={0} y={0} width={18} height={20} filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy={4} />
          <feGaussianBlur stdDeviation={10} />
          <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
          <feColorMatrix values="0 0 0 0 0.709804 0 0 0 0 0.862745 0 0 0 0 0.411765 0 0 0 0.3 0" />
          <feBlend in2="shape" result="effect1_innerShadow" />
        </filter>
      </defs>
    </svg>
  );
}
export default SvgMarket;
