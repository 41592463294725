import { NavigationName, NavigationPath } from '../../../../lib/constants/enums';
import { NavigationData } from '../../../../lib/common-interfaces';

export const navData: NavigationData = [
  {
    name: NavigationName.slimmingSchool,
    path: NavigationPath.slimmingSchool,
    id: 1,
  },
  // {
  //   name: NavigationName.academyOfNutrition,
  //   path: NavigationPath.academyOfNutrition,
  //   id: 2,
  // },
  {
    name: NavigationName.onlineProfessionAcademy,
    path: NavigationPath.promotionAcademy,
    id: 3,
  },
  {
    name: NavigationName.fitnessEquipment,
    path: NavigationPath.fitnessEquipment,
    id: 4,
  },
  // {
  //   name: NavigationName.fitnessTour,
  //   path: NavigationPath.fitnessTour,
  //   id: 5,
  // },
  {
    name: NavigationName.reviews,
    path: NavigationPath.reviews,
    id: 6,
  },
  {
    name: NavigationName.blog,
    path: NavigationPath.blog,
    id: 7,
  },
  {
    name: NavigationName.faceFitness,
    path: NavigationPath.faceFitness,
    id: 12,
  },
  // {
  //   name: NavigationName.FAQ,
  //   path: NavigationPath.FAQ,
  //   id: 8,
  // },
  {
    name: NavigationName.about,
    path: NavigationPath.about,
    id: 9,
  },
  {
    name: NavigationName.partners,
    path: NavigationPath.partners,
    id: 10,
    // link: 'https://www.instagram.com/medushkina.pr/',
  },
  {
    name: NavigationName.contacts,
    path: NavigationPath.contacts,
    id: 11,
    // link: 'https://www.instagram.com/medushkina.pr/',
  },
];
