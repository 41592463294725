import React, { useEffect, useState, useRef } from 'react';
import { Modal, Divider, Spin } from 'antd';
import cx from 'classnames';
import ReactMarkdown from 'react-markdown';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from '@reach/router';
import { graphql, Link, useStaticQuery } from 'gatsby';
import remarkGfm from 'remark-gfm';
import queryString from 'query-string';
import style from './style.module.scss';
import { StrapiPolitics } from '../../../../graphql-types';
import { getCookie, setCookie } from '../../../../lib/utils/cookie-utils';
import { Langs } from '../../../../lib/constants/enums';
import { pathTo } from '../../../../lib/utils';
import { findLegalEntity } from '../copyright';

const Politics: React.FC = () => {
  const [legalEntity, setLegalEntity] = useState(null);
  const [isView, setView] = useState<boolean>(false);
  const [currentItem, setCurrentItem] = useState<StrapiPolitics | null>(null);
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const scrollRef = useRef<HTMLDivElement>(null);

  const { allStrapiLegalEntities } = useStaticQuery(graphql`
    query getLegalEntitiesData {
      allStrapiLegalEntities {
        nodes {
          footer
          urlParam
          politics {
            additionalDescription
            description
            footer
            id
            popupTitle
            title
            hash
            descriptionEn
            additionalDescriptionEn
            footerEn
            popupTitleEn
            titleEn
          }
          countries {
            code
          }
        }
      }
    }
  `);

  const legalEntities = allStrapiLegalEntities.nodes;

  useEffect(() => {
    const { legalEntity: urlParamLegalEntity } = queryString.parse(location.search);
    findLegalEntity(urlParamLegalEntity, setLegalEntity, legalEntities);
    if (location.hash) {
      setView(true);
      setCurrentItem((legalEntity || legalEntities[0]).politics.find(i => i.hash === location.hash.replace('#', '')))
    }

  }, [legalEntities, legalEntity]);

  return (
    <>
      <ul className={style.politics}>
        {legalEntity ? (
          legalEntity.politics.map((el, index) => (
            <li
              onClick={() => {
                setView(true);
                setCurrentItem(legalEntity.politics[index]);
                navigate(`#${legalEntity.politics[index].hash}`, { replace: true });
              }}
              key={el.id}
              className={style.politicsItem}
            >
              {el.title}
            </li>
          ))
        ) : (
          <div className={style.politicsSpin}>
            <Spin />
          </div>
        )}
      </ul>
      <Modal
        title={
          <div className={style.popupHead} ref={scrollRef}>
            {legalEntity &&
              legalEntity.politics.map((el, index) => (
                <div
                  onClick={() => {
                    setCurrentItem(() => legalEntity.politics[index]);
                    navigate(`#${legalEntity.politics[index].hash}`, { replace: true });
                  }}
                  className={cx(style.popupHeadItem, { [style.popupHeadItemActive]: legalEntity.politics[index].id === currentItem?.id })}
                  key={index}
                >
                  {i18n.language === Langs.en ? el?.popupTitleEn || el?.popupTitle : el?.popupTitle || el?.popupTitleEn}
                </div>
              ))}
          </div>
        }
        visible={isView}
        onOk={() => {
          if (!getCookie('accept')) setCookie('accept', 'true');
          setView(false);
          if (location.hash) navigate(pathTo(`${location.pathname}`));
        }}
        okText={t('footer:politics.accept')}
        cancelText={t('footer:politics.cancel')}
        onCancel={() => {
          setView(false);
          if (location.hash) navigate(pathTo(`${location.pathname}`));
        }}
        width={1350}
        style={{ top: 0 }}
      >
        <h2 className={style.popuptitle}>{i18n.language === Langs.en ? currentItem?.titleEn || currentItem?.title : currentItem?.title || currentItem?.titleEn}</h2>
        {currentItem?.description && (
          <div className={style.popupDescription}>
            <ReactMarkdown
              transformImageUri={img => `${process.env.STRAPI_API_URL}${img}`}
              children={i18n.language === Langs.en ? currentItem?.descriptionEn || currentItem?.description : currentItem?.description || currentItem?.descriptionEn}
              remarkPlugins={[remarkGfm]}
            />
          </div>
        )}
        {currentItem?.additionalDescription && (
          <>
            <Divider />
            <div className={style.popupDescription}>
              <ReactMarkdown
                transformImageUri={img => `${process.env.STRAPI_API_URL}${img}`}
                children={ i18n.language === Langs.en ? currentItem?.additionalDescriptionEn || currentItem?.additionalDescription : currentItem?.additionalDescription || currentItem?.additionalDescriptionEn}
                remarkPlugins={[remarkGfm]}
              />
              {legalEntity && (
                <div>
                  <Link className={style.termsLink} to={pathTo('/partners')}>
                    {t('footer:politics.navigation.advertising')}
                  </Link>
                </div>
              )}
            </div>
          </>
        )}
        {currentItem?.footer && (
          <>
            <Divider />
            <div className={style.popupDescription}>
              <ReactMarkdown
                transformImageUri={img => `${process.env.STRAPI_API_URL}${img}`}
                children={i18n.language === Langs.en ? currentItem?.footerEn || currentItem?.footer : currentItem?.footer || currentItem?.footerEn}
                remarkPlugins={[remarkGfm]}
              />
            </div>
          </>
        )}
      </Modal>
    </>
  );
};

export default Politics;
