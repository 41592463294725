import * as React from 'react';
function SvgVector(props) {
  return (
    <svg width={13} height={18} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M12.336 0H.445C.199 0 0 .2 0 .445V17.11a.889.889 0 001.408.723l4.983-3.572 4.982 3.572a.89.89 0 001.409-.723V.445c0-.246-.2-.445-.446-.445z"
        fill="#A4CE41"
      />
      <path
        d="M12.336 0H.445C.199 0 0 .2 0 .445V17.11a.889.889 0 001.408.723l4.983-3.572 4.982 3.572a.89.89 0 001.409-.723V.445c0-.246-.2-.445-.446-.445z"
        fill="url(#Vector_svg__paint0_linear)"
      />
      <defs>
        <linearGradient id="Vector_svg__paint0_linear" x1={6.487} y1={0} x2={6.487} y2={18} gradientUnits="userSpaceOnUse">
          <stop stopColor="#D3E321" />
          <stop offset={1} stopColor="#fff" stopOpacity={0} />
        </linearGradient>
      </defs>
    </svg>
  );
}
export default SvgVector;
