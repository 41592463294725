import axios from 'axios';

interface LogPas {
  login: string;
  password: string;
}

export const auth = (data: LogPas) => {
  return axios
    .post('https://stage.club.goslim.pro/accounts/external-login/', `login=${data.login}&password=${data.password}`, { maxRedirects: 0 })
    .then(response => console.log(response));
};
