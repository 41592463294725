import React from 'react';
import style from './style.module.scss';
import { Layouts } from '../components';
import TopFooterContent from './topContent/TopContent';
import BottomFooterContent from './bottomContent/BottomContent';

const Footer: React.FC = () => {
  const { Container } = Layouts;
  return (
    <footer className={style.footer}>
      <Container className={style.container}>
        <TopFooterContent />
        <BottomFooterContent />
      </Container>
    </footer>
  );
};

export default Footer;
