import * as React from 'react';
function SvgClose(props) {
  return (
    <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M13.214 12l6.152-7.334a.186.186 0 00-.143-.307h-1.87c-.11 0-.216.05-.288.134l-5.075 6.05-5.074-6.05a.375.375 0 00-.288-.134h-1.87a.186.186 0 00-.143.307L10.767 12l-6.152 7.334a.186.186 0 00.143.307h1.87c.11 0 .216-.05.288-.134l5.074-6.05 5.075 6.05c.07.084.175.134.288.134h1.87c.16 0 .246-.185.143-.307L13.214 12z"
        fill="#000"
      />
    </svg>
  );
}
export default SvgClose;
