import React from 'react';

import style from './style.module.scss';

import arrowSmallBlackSvg from '../../Icons/arrowSmallBlack.svg';
import ReceiptsBookWrapper from '../../assets/images/receipts-book-wrapper.png';
import Button from '../Button/Button';


export interface TextBannerProps {
  banner: {
    title: string;
    subtitle: string;
    buttonText: string;
    buttonUrl: string;
  };
}


const TextBanner = ({ banner: { title, subtitle, buttonText, buttonUrl } }: TextBannerProps) => {
  return (
    <div className={style.textBannerWrapper}>
      <div className={style.textBannerWrapperImage}>
        <img className={style.textBannerImage} src={ReceiptsBookWrapper}/>
      </div>
      <div className={style.textBannerWrapperText}>
        <span className={style.title}>{title}</span>
        <span className={style.subtitle}>{subtitle}</span>

        <a href={buttonUrl} target="_blank" className={style.link}>
          <Button title={buttonText}
                  uppercase
                  css={{ width: '100%' }}
                  after={<img className={style.buttonArrowImage} src={arrowSmallBlackSvg} alt="arrow"/>}/>
        </a>
      </div>
    </div>
  );
};

export default TextBanner;
