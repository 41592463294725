import React, { useState, Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { Radio, Checkbox } from 'antd';
import css from '../style.module.scss';

import eyeCloseIcon from '../../../../assets/icons/eye-close.svg';
import eyeOpenIcon from '../../../../assets/icons/eye-open.svg';

import Button from '../../../Button/Button';

interface RegFormProps {
  setForm?: Dispatch<SetStateAction<string>>;
  openNotification: Function;
}

interface DataState {
  email: string;
  name: string;
  lastName: string;
  gender: string;
  phone: string;
  pass1: string;
  pass2: string;
  age: boolean;
  terms: boolean;
}

const RegForm: React.FC<RegFormProps> = ({ setForm, openNotification }) => {
  const { t } = useTranslation();

  const [visible, setVisible] = useState(false);

  const [data, setData] = useState<DataState>({
    email: '',
    name: '',
    lastName: '',
    gender: 'female',
    phone: '',
    pass1: '',
    pass2: '',
    age: false,
    terms: false,
  });

  const handelClick = () => {
    const reg = /([A-Za-z0-9_\-\.])\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

    if ((data.name && data.lastName && data.phone) === '') {
      return openNotification(t('shell:signin.regErrorEmpty'));
    }
    if (!reg.test(data.email)) {
      return openNotification(t('shell:signin.errorEmail'));
    }
    if (data.pass1 !== data.pass2 || (data.pass1 || data.pass2) === '') {
      return openNotification(t('shell:signin.regErrorPass'));
    }
  };

  return (
    <div className={css.form}>
      <div className={css.title}> {t('shell:signin.regForm')}</div>
      <div className={css.inputWrapper}>
        <input
          type="text"
          placeholder={t('shell:signin.email')}
          value={data.email}
          onChange={e => setData({ ...data, email: e.target.value })}
        />
      </div>
      <div className={css.inputWrapper}>
        <input
          type="text"
          placeholder={t('shell:signin.name')}
          value={data.name}
          onChange={e => setData({ ...data, name: e.target.value })}
        />
      </div>
      <div className={css.inputWrapper}>
        <input
          type="text"
          placeholder={t('shell:signin.lastName')}
          value={data.lastName}
          onChange={e => setData({ ...data, lastName: e.target.value })}
        />
      </div>
      <div className={css.genderBlocks}>
        <Radio.Group value={data.gender} onChange={e => setData({ ...data, gender: e.target.value })}>
          <div className={css.block} style={{ marginRight: '6px' }}>
            <Radio value="female">{t('shell:signin.female')}</Radio>
          </div>
          <div className={css.block} style={{ marginLeft: '6px' }}>
            <Radio value="make">{t('shell:signin.male')}</Radio>
          </div>
        </Radio.Group>
      </div>
      <div className={css.inputWrapper}>
        <input
          type="number"
          placeholder={t('shell:signin.phone')}
          value={data.phone}
          onChange={e => setData({ ...data, phone: e.target.value })}
        />
      </div>
      <div className={css.inputWrapper}>
        <input
          type={!visible ? 'password' : 'text'}
          placeholder={t('shell:signin.password')}
          value={data.pass1}
          onChange={e => setData({ ...data, pass1: e.target.value })}
        />
        <div onClick={() => (visible ? setVisible(false) : setVisible(true))}>
          {visible ? <img src={eyeOpenIcon} alt="icon" /> : <img src={eyeCloseIcon} alt="icon" />}
        </div>
      </div>
      <div className={css.inputWrapper}>
        <input
          type={!visible ? 'password' : 'text'}
          placeholder={t('shell:signin.repeatPassword')}
          value={data.pass2}
          onChange={e => setData({ ...data, pass2: e.target.value })}
        />
        <div onClick={() => (visible ? setVisible(false) : setVisible(true))}>
          {visible ? <img src={eyeOpenIcon} alt="icon" /> : <img src={eyeCloseIcon} alt="icon" />}
        </div>
      </div>
      <div className={css.checkbox}>
        <div>
          <Checkbox onChange={e => setData({ ...data, age: e.target.checked })}>{t('shell:signin.regFormAgeRestrictions')}</Checkbox>
        </div>
        <div>
          <Checkbox onChange={e => setData({ ...data, terms: e.target.checked })}>
            {t('shell:signin.regFormTerms')}{' '}
            <a href="https://goslim.pro/vexor-limited/terms-and-conditions-ru-2/" rel="noreferrer" target="_blank">
              Terms of Use
            </a>{' '}
            {t('shell:signin.and')}{' '}
            <a href="https://goslim.pro/vexor-trade-ou/privacy-policy-ru/" rel="noreferrer" target="_blank">
              Privacy Policy
            </a>
          </Checkbox>
        </div>
      </div>
      <Button title={t('shell:signin.regFormBtn')} border fontWeight={600} onClick={handelClick} />
    </div>
  );
};

export default RegForm;
