import * as React from 'react';
function SvgDownloadBtn(props) {
  return (
    <svg width={12} height={12} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M5.902 8.328a.125.125 0 00.196 0l1.75-2.214a.125.125 0 00-.098-.202H6.592V.625A.125.125 0 006.467.5H5.53a.125.125 0 00-.125.125v5.286H4.25a.125.125 0 00-.098.202l1.75 2.215zm5.817-.547h-.938a.125.125 0 00-.125.125v2.407H1.344V7.905a.125.125 0 00-.125-.125H.28a.125.125 0 00-.125.125V11a.5.5 0 00.5.5h10.688a.5.5 0 00.5-.5V7.906a.125.125 0 00-.125-.125z"
        fill="#fff"
      />
    </svg>
  );
}
export default SvgDownloadBtn;
