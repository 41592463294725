import * as React from 'react';
function SvgLink(props) {
  return (
    <svg width={17} height={16} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M9.469 10.397a.125.125 0 00-.177 0l-1.816 1.815c-.84.84-2.259.93-3.187 0a2.244 2.244 0 010-3.187l1.816-1.816a.125.125 0 000-.176l-.622-.622a.125.125 0 00-.177 0L3.491 8.226a3.378 3.378 0 000 4.782 3.38 3.38 0 004.78 0l1.816-1.816a.125.125 0 000-.177l-.618-.618zm4.04-7.407a3.378 3.378 0 00-4.781 0L6.911 4.806a.125.125 0 000 .177l.62.62a.125.125 0 00.177 0l1.815-1.816c.841-.84 2.26-.93 3.188 0 .93.93.84 2.347 0 3.188L10.895 8.79a.126.126 0 000 .177l.622.622a.125.125 0 00.177 0l1.815-1.816a3.383 3.383 0 000-4.783zm-3.476 2.827a.125.125 0 00-.177 0L6.317 9.354a.125.125 0 000 .177l.619.619a.126.126 0 00.176 0l3.538-3.538a.125.125 0 000-.176l-.617-.619z"
        fill="#A4CE41"
      />
      <path
        d="M9.469 10.397a.125.125 0 00-.177 0l-1.816 1.815c-.84.84-2.259.93-3.187 0a2.244 2.244 0 010-3.187l1.816-1.816a.125.125 0 000-.176l-.622-.622a.125.125 0 00-.177 0L3.491 8.226a3.378 3.378 0 000 4.782 3.38 3.38 0 004.78 0l1.816-1.816a.125.125 0 000-.177l-.618-.618zm4.04-7.407a3.378 3.378 0 00-4.781 0L6.911 4.806a.125.125 0 000 .177l.62.62a.125.125 0 00.177 0l1.815-1.816c.841-.84 2.26-.93 3.188 0 .93.93.84 2.347 0 3.188L10.895 8.79a.126.126 0 000 .177l.622.622a.125.125 0 00.177 0l1.815-1.816a3.383 3.383 0 000-4.783zm-3.476 2.827a.125.125 0 00-.177 0L6.317 9.354a.125.125 0 000 .177l.619.619a.126.126 0 00.176 0l3.538-3.538a.125.125 0 000-.176l-.617-.619z"
        fill="url(#Link_svg__paint0_linear)"
      />
      <defs>
        <linearGradient id="Link_svg__paint0_linear" x1={8.589} y1={1.999} x2={8.589} y2={13.998} gradientUnits="userSpaceOnUse">
          <stop stopColor="#D3E321" />
          <stop offset={1} stopColor="#fff" stopOpacity={0} />
        </linearGradient>
      </defs>
    </svg>
  );
}
export default SvgLink;
