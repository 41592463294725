import * as React from 'react';
function SvgYoutube(props) {
  return (
    <svg width={30} height={30} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M14.386 17.56l3.902-2.926-3.902-2.927v5.854z" fill="#A4CE41" />
      <path d="M14.386 17.56l3.902-2.926-3.902-2.927v5.854z" fill="url(#Youtube_svg__paint0_linear)" />
      <path
        d="M15.362 0C7.28 0 .728 6.553.728 14.634S7.28 29.268 15.362 29.268c8.08 0 14.634-6.553 14.634-14.634C29.996 6.554 23.443 0 15.362 0zm9.144 14.65s0 2.967-.377 4.398a2.292 2.292 0 01-1.612 1.612c-1.43.377-7.155.377-7.155.377s-5.71 0-7.156-.392a2.292 2.292 0 01-1.612-1.612c-.377-1.416-.377-4.399-.377-4.399s0-2.968.377-4.399a2.338 2.338 0 011.612-1.627c1.431-.376 7.156-.376 7.156-.376s5.724 0 7.155.391c.784.211 1.401.829 1.612 1.612.392 1.431.377 4.414.377 4.414z"
        fill="#A4CE41"
      />
      <path
        d="M15.362 0C7.28 0 .728 6.553.728 14.634S7.28 29.268 15.362 29.268c8.08 0 14.634-6.553 14.634-14.634C29.996 6.554 23.443 0 15.362 0zm9.144 14.65s0 2.967-.377 4.398a2.292 2.292 0 01-1.612 1.612c-1.43.377-7.155.377-7.155.377s-5.71 0-7.156-.392a2.292 2.292 0 01-1.612-1.612c-.377-1.416-.377-4.399-.377-4.399s0-2.968.377-4.399a2.338 2.338 0 011.612-1.627c1.431-.376 7.156-.376 7.156-.376s5.724 0 7.155.391c.784.211 1.401.829 1.612 1.612.392 1.431.377 4.414.377 4.414z"
        fill="url(#Youtube_svg__paint1_linear)"
      />
      <defs>
        <linearGradient id="Youtube_svg__paint0_linear" x1={16.367} y1={11.707} x2={16.367} y2={17.561} gradientUnits="userSpaceOnUse">
          <stop stopColor="#D3E321" />
          <stop offset={1} stopColor="#fff" stopOpacity={0} />
        </linearGradient>
        <linearGradient id="Youtube_svg__paint1_linear" x1={15.581} y1={0} x2={15.581} y2={29.268} gradientUnits="userSpaceOnUse">
          <stop stopColor="#D3E321" />
          <stop offset={1} stopColor="#fff" stopOpacity={0} />
        </linearGradient>
      </defs>
    </svg>
  );
}
export default SvgYoutube;
