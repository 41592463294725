import React, { ReactNode, useMemo } from 'react';
import { useMediaQuery } from 'react-responsive';

interface AdaptiveLayoutProps {
  children: ReactNode | React.FC;
  onDesktop?: boolean;
  onMobile?: boolean;
  onTablet?: boolean;
  onTabletLarge?: boolean;
  onTabletMedium?: boolean;
}

const AdaptiveLayout: React.FC<AdaptiveLayoutProps> = ({ children, onDesktop, onTablet, onTabletLarge, onTabletMedium, onMobile }) => {
  const isXL = useMediaQuery({ minWidth: 1331 });
  const isLG = useMediaQuery({ minWidth: 960, maxWidth: 1330 });
  const isLS = useMediaQuery({ minWidth: 766, maxWidth: 960 });
  const isMD = useMediaQuery({ minWidth: 766, maxWidth: 1000 });
  const isSM = useMediaQuery({ minWidth: 480, maxWidth: 765 });
  const isXS = useMediaQuery({ maxWidth: 480 });

  const show = useMemo(() => {
    switch (true) {
      case onMobile && (isXS || isSM):
        return true;
      case onTablet && (isLG || isMD):
        return true;
      case onTabletMedium && isLS:
        return true;
      case onTabletLarge && isLG:
        return true;
      case onDesktop && isXL:
        return true;
      default:
        return false;
    }
  }, [isXL, isLG, isMD, isSM, isXS]);

  return <>{show ? children : null}</>;
};

export default AdaptiveLayout;
