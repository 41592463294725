export const getCookie = (name: string) => {
  const matches = document.cookie.match(new RegExp(`(?:^|; )${name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1')}=([^;]*)`));
  return matches ? decodeURIComponent(matches[1]) : undefined;
};

export const setCookie = (name: string, value: string, options: any = {}) => {
  const config = {
    path: '/',
    // set default properties
    ...options,
  };

  if (config.expires instanceof Date) {
    config.expires = config.expires.toUTCString();
  }

  let updatedCookie = `${encodeURIComponent(name)}=${encodeURIComponent(value)}`;

  for (const optionKey in config) {
    updatedCookie += `; ${optionKey}`;
    const optionValue = config[optionKey];
    if (optionValue !== true) {
      updatedCookie += `= ${optionValue}`;
    }
  }
  document.cookie = updatedCookie;
};

export const deleteCookie = (name: string) => {
  setCookie(name, '', {
    'max-age': -1,
  });
};
