import React from 'react';
import ImageGallery, { ReactImageGalleryItem } from 'react-image-gallery';
import 'react-image-gallery/styles/scss/image-gallery.scss';
import GQL from '../../graphql-types';

export interface GalleryProps {
  images: GQL.PayformMediaResponseDto[];
}

export const Gallery = ({ images }: GalleryProps) => {
  const items: ReactImageGalleryItem[] = images.map((image) => {
    return { original: image.path, thumbnail: image.path };
  });

  return <ImageGallery showPlayButton={false} showFullscreenButton={false} items={items} />;
};
