import { CircleBgType } from '../../lib/constants/enums';

import Promo from '../../assets/images/promo.png';
import About from '../../assets/images/about.png';
import Author from '../../assets/images/author.png';
import Partners from '../../assets/images/partners.png';
import FitnessEquipment from '../../assets/images/fitnessEquipment.png';
import List from '../../assets/images/list.png';
import Contact from '../../assets/images/contact.png';

type CircleBg = {
  [key in CircleBgType]: string;
};

export const circleBgData: CircleBg = {
  promo: Promo,
  about: About,
  author: Author,
  partners: Partners,
  fitnessEquipment: FitnessEquipment,
  list: List,
  contact: Contact,
};
