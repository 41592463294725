import { colors } from '../../styles/variables';

export default class ColorService {
  hexToRGB = (hex: string, alpha?: number) => {
    const r = parseInt(hex.slice(1, 3), 16),
      g = parseInt(hex.slice(3, 5), 16),
      b = parseInt(hex.slice(5, 7), 16);
    return alpha ? `rgba(${r},${g},${b}, ${alpha})` : `rgb(${r},${g},${b})`;
  };

  parseColor = (color: string): { hex: string; opacity: number } => {
    const arr: Array<number> = [];
    color.replace(/[\d+\\.]+/g, v => {
      arr.push(parseFloat(v));
    });
    return {
      hex:
        '#' +
        arr
          .slice(0, 3)
          .map(this.toHex)
          .join(''),
      opacity: arr.length === 4 ? arr[3] : 1,
    };
  };

  toHex = (int: number) => {
    const hex = int.toString(16);
    return hex.length === 1 ? `0${hex}` : hex;
  };

  rednderColor = () => {
    return `#${Math.random()
      .toString(16)
      .substr(-6)}`; //from 0 to ffffff
  };

  generateGradient = (firstColor: string, secondColor: string, deg: number) => {
    return `-webkit-linear-gradient(${deg}deg, ${firstColor} 0%, ${secondColor} 100%)`;
  };

  generateShadow = (shadowParams: string, color: string, opacity?: number) => {
    const parseColor = this.hexToRGB(color, opacity);
    return `${shadowParams} ${parseColor}`;
  };

  getContrastYIQ = (hexcolor: string) => {
    if (hexcolor.indexOf('#') === 0) {
      hexcolor = hexcolor.slice(1);
    }
    var r = parseInt(hexcolor.substr(0, 2), 16);
    var g = parseInt(hexcolor.substr(2, 2), 16);
    var b = parseInt(hexcolor.substr(4, 2), 16);
    var yiq = (r * 299 + g * 587 + b * 114) / 1000;
    return yiq >= 128 ? 'black' : 'white';
  };

  mix = (color_1: string, color_2: string, weight?: number) => {
    function d2h(d) {
      return d.toString(16);
    } // convert a decimal value to hex
    function h2d(h) {
      return parseInt(h, 16);
    } // convert a hex value to decimal

    weight = typeof weight !== 'undefined' ? weight : 50; // set the weight to 50%, if that argument is omitted

    var color = '#';

    for (var i = 0; i <= 5; i += 2) {
      // loop through each of the 3 hex pairs—red, green, and blue
      var v1 = h2d(color_1.substr(i, 2)), // extract the current pairs
        v2 = h2d(color_2.substr(i, 2)),
        // combine the current pairs from each source color, according to the specified weight
        val = d2h(Math.floor(v2 + (v1 - v2) * (weight / 100.0)));

      while (val.length < 2) {
        val = '0' + val;
      } // prepend a '0' if val results in a single digit

      color += val; // concatenate val to our new color string
    }

    return color;
  };

  boxgradient = (from: string, to: string, weight: number = 0) => {
    const fromReplace = from.replace('#', '');
    const toReplace = to.replace('#', '');
    const mixMain = this.mix(fromReplace, toReplace);
    const mixSubFrom = this.mix(mixMain.replace('#', ''), fromReplace);
    const mixSubTo = this.mix(mixMain.replace('#', ''), toReplace);
    return `-1px 1px 0 ${weight}px ${this.hexToRGB(mixSubFrom, 0.25)},
      0 -1px 0 ${weight}px ${this.hexToRGB(mixMain, 0.5)},
      0 1px 0 ${weight}px ${this.hexToRGB(mixMain, 0.5)},
      1px -1px 0 ${weight}px ${this.hexToRGB(mixSubTo, 0.25)},
      1px 1px 0 ${weight}px ${this.hexToRGB(mixSubTo, 0.25)},
      1px 0 0 ${weight}px ${this.hexToRGB(to, 0.75)}`;
  };
}
