import React from 'react';
import style from './style.module.scss';
import { useMediaQuery } from 'react-responsive';

const Banner = ({ banner }) => {
  const isXS = useMediaQuery({ maxWidth: 480 });
  const isMD = useMediaQuery({ minWidth: 480, maxWidth: 767 });
  const isLG = useMediaQuery({ minWidth: 768, maxWidth: 1200 });
  const isXL = useMediaQuery({ minWidth: 1200 });

  return (
        <a href={banner.link} className={style.img}>
          {(isXS || isMD) && <img src={`${process.env.STRAPI_API_URL}${banner.mobileImage.url}`}/>}
          {(isLG || isXL) && <img src={`${process.env.STRAPI_API_URL}${banner.desktopImage.url}`}/>}
        </a>
  );
};

export default Banner;
