import gql from 'graphql-tag';

export const EXCHANGE_RATE_REQUEST = gql`
  {
    ExchangeRate {
      id
      currency
      rate
    }
  }
`;
