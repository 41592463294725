import styled from 'styled-components';
import PresentImage from '../../assets/images/present.png';
import CentimeterImage from '../../assets/images/centimeter.png';
import { colors } from '../../styles/variables';

export const ButtonWrapper = styled.div`
  width: ${props => (props.width ? `calc(${props.width}px - 20px)` : '100%')};
  margin: ${props => (props.border ? `10px` : '0')};
  background: ${props => props.active && '#d9d9d9'};
  padding: ${props => props.active && '2px'};
  border-radius: ${props => (props.active ? '53px' : '0')};
`;

export const StyledButton = styled.button`
  font-weight: ${props => (props.fontWeight ? `${props.fontWeight}` : '400')};
  text-transform: ${props => (props.uppercase ? 'uppercase' : 'none')};
  font-size: ${props => props.fontSize && `${props.fontSize}px!important`};
  background: ${props => (props.active && '#fff!important') || (props.bgColor && `${props.bgColor}!important`)};
  color: ${props => props.fontColor || colors.btngray};
  &:hover {
    box-shadow: ${props => (props.shadowColor ? props.shadowColor : '0px 14px 20px rgba(178, 213, 91, 0.4)')};
  }
`;

export const Present = styled.div`
  background: url(${PresentImage}) center top / cover no-repeat;
  display: block;
  position: absolute;
  width: 110px;
  height: 110px;
  right: 0;
  bottom: -16px;
  @media (max-width: 765px) {
    width: 80px;
    height: 80px;
    bottom: -8px;
    right: -10px;
  }
`;

export const Centimeter = styled.div`
  background: url(${CentimeterImage}) center top / cover no-repeat;
  display: block;
  position: absolute;
  width: 120px;
  height: 110px;
  right: 0;
  bottom: -65px;
  @media (max-width: 765px) {
    width: 80px;
    height: 80px;
  }
`;
