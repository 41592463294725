import React from 'react';
import { Link } from 'gatsby';
import { LogoDark } from '../../Icons';
import { LogoLight } from '../../Icons';
import style from './style.module.scss';
import { LogoType } from '../../lib/constants/enums';
import { AdaptiveLayout } from '../Layouts/Layouts';

interface LogoProps {
  type: LogoType;
  link?: boolean;
}

const Logo: React.FC<LogoProps> = props => {
  const { type, link } = props;

  const renderLogo =
    type === LogoType.black ? (
      <>
        <AdaptiveLayout onDesktop onTablet>
          <LogoDark  />
        </AdaptiveLayout>
        <AdaptiveLayout onMobile>
          <LogoDark />
        </AdaptiveLayout>
      </>
    ) : (
      <>
        <AdaptiveLayout onDesktop onTablet>
          <LogoLight />
        </AdaptiveLayout>
        <AdaptiveLayout onMobile>
          <LogoLight />
        </AdaptiveLayout>
      </>
    );

  if (link) {
    return (
      <Link className={style.logo__wrapper} to={'https://skillzup.pro/'}>
        {renderLogo}
      </Link>
    );
  }

  return <div className={style.logo__wrapper}>{renderLogo}</div>;
};

export default Logo;
