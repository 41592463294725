import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'gatsby';
import { useSelector, useDispatch } from 'react-redux';
import { CheckOutlined, LoadingOutlined, ShoppingCartOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Col, Card, Typography, Button, Tag, Tooltip, Select, InputNumber } from 'antd';
import { transparentize } from 'polished';
import {
  PayformProductResponseDto,
  PayformOptionsCategoryResponseDto,
  PayformProjectWithoutProductInDetailResponseDto,
  LanguageType,
} from '../../../../graphql-types';
import css from './productitem.module.scss';
import Container from '../../../../components/Layouts/Container/Container';
import { AddToCartSagaActionCreator } from '../../../../redux/cart/cart.actions';
import { RootModel } from '../../../../redux/store';
import { ColorOption, ISelectedProject, CartItem } from '../../../../lib/common-interfaces';
import { pathTo } from '../../../../lib/utils';
import { CurrencySelector } from '../../../../redux/currency/currency.selector';
import { useRates } from '../../../../lib/hooks/rates';
import { ProductType } from '@altermeliora/payform-types';
import _ from 'lodash';
import { useQuery } from '@apollo/react-hooks';
import moment from 'moment';
import { CurrencySymbols, fixedNum } from '../../../../lib/utils';
import { OPTIONS_CATEGORY_REQUEST } from '../../../../api/api-apollo/query/optionCategory';
import { ProductCartType } from '../../../../lib/constants/enums';
import classnames from 'classnames';

const productImageStub = require('../../../../assets/icons/product-image-stub.svg');

interface ProductItemProps {
  product: PayformProductResponseDto;
  type?: ProductCartType;
}

export interface GCategoryItems {
  OptionsCategory: PayformOptionsCategoryResponseDto[];
}

export const ProductItem: React.FC<ProductItemProps> = ({ product, type = ProductCartType.default }) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { id } = product;
  const { loading, clickedId } = useSelector((state: RootModel) => ({
    loading: state.cart.status.loading,
    clickedId: state.cart.status.clickedId,
  }));
  const { data: categories } = useQuery<GCategoryItems>(OPTIONS_CATEGORY_REQUEST);
  const { currency } = useSelector(CurrencySelector);
  const { Option } = Select;
  const { convert, rates } = useRates();

  const [colorSelected, updateColorSelected] = useState<ColorOption>();
  const [showTag, updateShowTag] = useState(false);
  const [hovered, updateHovered] = useState<string>();
  const [price, setPrice] = useState<number>(0);
  const [selectedOptionIds, updateSelectedOptionIds] = useState<Record<number, number>>({});
  const [activeProjects, setActiveProjects] = useState<PayformProjectWithoutProductInDetailResponseDto[]>([]);
  const [selectedProject, setSelectedProject] = useState<ISelectedProject>();
  const [inputQuantity, updateInputQuantity] = useState<number>(1);
  const [optionsCount, setOptionsCount] = useState<PayformOptionsCategoryResponseDto[]>([]);

  useEffect(() => {
    if (product.inDetail.material?.options && product.inDetail.material?.options.length > 0) {
      product.inDetail.material?.options.forEach(el => {
        categories?.OptionsCategory.map(option => {
          if (option.options.find(o => o.id === el.id)) {
            setOptionsCount(prev => (!prev.some(el => el.id === option.id) ? [...prev, option] : prev));
          }
        });
      });
    }
  }, [product, categories]);

  const onAddToCart = () => {
    if (
      (product.inDetail.material?.options &&
        product.inDetail.material?.options.length > 0 &&
        Object.values(selectedOptionIds).length !== optionsCount.length) ||
      (product.inDetail.material?.colorOptions && product.inDetail.material?.colorOptions.length > 0 && !colorSelected) ||
      (product.inDetail.projects && product.inDetail.projects.length > 0 && !selectedProject)
    ) {
      updateShowTag(true);
      return;
    }
    updateShowTag(false);
    updateSelectedOptionIds({});
    const productBody: CartItem = {
      product,
      quantity: inputQuantity,
    };

    if (selectedOptionIds) {
      productBody['selectedOptionIds'] = selectedOptionIds;
    }

    if (colorSelected) {
      productBody['colorOption'] = colorSelected;
    }

    if (selectedProject) {
      productBody['project'] = selectedProject;
    }

    dispatch(
      AddToCartSagaActionCreator({
        product: productBody,
        showNotification: true,
      })
    );
  };

  useEffect(() => {
    if (product && rates) {
      product.prices.map(price => {
        price.list?.map(it => {
          setPrice(convert(it.amount, it.currency, currency));
        });
      });
    }
  }, [product, rates, currency]);

  // filter active projects for select
  useEffect(() => {
    if (product) {
      const filteredProjects = product.inDetail.projects!.filter(project => {
        const currentDate = moment().format('YYYY-MM-DD');
        const startSales = moment(project.saleStartAt).format('YYYY-MM-DD');
        const endSales = moment(project.saleEndAt).format('YYYY-MM-DD');
        return moment(currentDate).isBetween(startSales, endSales);
      });
      setActiveProjects(filteredProjects);
    }
  }, [product]);

  const changeInputQuantity = (value?: number) => {
    if (value) {
      updateInputQuantity(value);
    }
  };

  return (
    <Col xl={8} lg={8} md={12} sm={24} key={id} className={css.col}>
      <Card
        className={classnames(css.card, { [css.productWrapper]: type === ProductCartType.description })}
        cover={
          type === ProductCartType.default && (
            <Link
              className={product.photos && product.photos.length > 0 ? '' : css.image_wrapper}
              key={id}
              to={pathTo(`product/${product.slug}`)}
            >
              <img
                className={css.productImage}
                src={product.photos && product.photos.length > 0 ? product.photos[0].path : productImageStub}
                alt={product.slug}
                loading="lazy"
              />
            </Link>
          )
        }
      >
        <Container className={css.cardBodyContainer}>
          <Container>
            <Typography.Title level={4} className={css.cardTypography}>
              {product.name[i18n.language as LanguageType]}
            </Typography.Title>
            <Typography.Title level={3} className={css.cardTypography}>
              {`${fixedNum(price)} ${CurrencySymbols[currency]}`}
            </Typography.Title>
          </Container>
          {type === ProductCartType.description && (
            <Container className={css.inputContainter}>
              <InputNumber
                min={1}
                max={10}
                value={inputQuantity}
                size="large"
                className={css.quantityInput}
                onChange={value => changeInputQuantity(value)}
              />
            </Container>
          )}
          {product.inDetail.type === ProductType.project && (
            <>
              <Container className={css.projectSelectWrapper}>
                <Select
                  onSelect={(projectId: string) => {
                    const project = activeProjects.find(pr => pr.id === projectId);
                    if (project) {
                      setSelectedProject({
                        name: project.name,
                        id: project.id,
                      });
                    }
                  }}
                  placeholder={t('shell:placeholder.season')}
                  className={css.select}
                >
                  {activeProjects &&
                    activeProjects.map(projects => {
                      return (
                        <Option key={projects.id} value={projects.id}>
                          {`${projects.name[i18n.language as 'en' | 'ru']}`}
                        </Option>
                      );
                    })}
                </Select>
              </Container>
              {product.inDetail.projects!.length > 0 && !selectedProject && showTag && (
                <Container className={css.warningContainter}>
                  <Tag color="red" className={css.tag}>
                    {t('shell:product.seasonIsRequired')}
                  </Tag>
                </Container>
              )}
            </>
          )}
          {product.inDetail.type !== ProductType.project && (
            <Container className={css.lowerBlock}>
              {product.inDetail.material?.colorOptions && product.inDetail.material.colorOptions.length > 0 && (
                <>
                  <Container className={css.optionContainer}>
                    {product.inDetail.material.colorOptions.map(color => {
                      return (
                        <Tooltip title={color.name[i18n.language as 'en' | 'ru']} placement="top" arrowPointAtCenter key={color.id}>
                          <Button
                            size={hovered === color.id || colorSelected?.contentful_id === color.id ? 'large' : 'small'}
                            shape="circle"
                            style={{
                              backgroundColor: transparentize(0.5, color.hexCode as string),
                              border: `2px solid ${color.hexCode as string}`,
                              margin: '7px',
                            }}
                            onMouseEnter={() => updateHovered(color.id as string)}
                            onMouseLeave={() => updateHovered(undefined)}
                            onClick={() => {
                              updateColorSelected({
                                contentful_id: color.id as string,
                                name: (color.name as unknown) as string,
                                hexCode: color.hexCode as string,
                              });
                            }}
                          >
                            {colorSelected?.contentful_id === color.id && <CheckOutlined style={{ color: color.hexCode }} />}
                          </Button>
                        </Tooltip>
                      );
                    })}
                  </Container>
                  {product.inDetail.material.colorOptions && !colorSelected && showTag && (
                    <Container className={css.warningContainter}>
                      <Tag color="red" className={css.tag}>
                        {t('shell:product.colorIsRequired')}
                      </Tag>
                    </Container>
                  )}
                </>
              )}
              <Container className={css.optionBlock}>
                {product.inDetail.material?.options &&
                  product.inDetail.material?.options.length > 0 &&
                  categories &&
                  categories.OptionsCategory.map((option: PayformOptionsCategoryResponseDto) => {
                    if (
                      _.intersection(
                        option.options.map(o => o.id),
                        product.inDetail.material! ? product.inDetail.material!.options.map(o => o.id) : []
                      ).length
                    ) {
                      return (
                        <Col span={12} key={option.id}>
                          <Form.Item className={css.optionTitle} label={option.name[i18n.language]}>
                            <Select
                              onSelect={(optionId: number) => {
                                updateSelectedOptionIds(prevOptions => ({ ...prevOptions, [option.id]: optionId }));
                              }}
                              value={selectedOptionIds[option.id]}
                              placeholder={t('shell:placeholder.option')}
                              optionLabelProp="children"
                              className={css.select}
                              disabled={
                                _.intersection(
                                  option.options.map(o => o.id),
                                  Object.values(selectedOptionIds)
                                ).length > 0
                              }
                            >
                              {product &&
                                product.inDetail.material?.options.map(productOption => {
                                  if (option.options.map(o => o.id).includes(productOption.id)) {
                                    return (
                                      <Option key={productOption.id} value={productOption.id}>
                                        {`${productOption.name[i18n.language as 'en' | 'ru']}`}
                                      </Option>
                                    );
                                  }
                                })}
                            </Select>
                          </Form.Item>
                        </Col>
                      );
                    }
                  })}
              </Container>
            </Container>
          )}
          {product.inDetail.type !== ProductType.project &&
            Object.values(selectedOptionIds).length < (categories?.OptionsCategory.length || 0) &&
            showTag && (
              <Container className={css.warningContainter}>
                <Tag color="red" className={css.tag}>
                  {t('shell:product.optionIsRequired')}
                </Tag>
              </Container>
            )}
          <Container className={css.buttonContainer}>
            <Button type="primary" size="large" className={css.cardButton} disabled={loading} onClick={onAddToCart}>
              {loading && clickedId === +product.id && <LoadingOutlined />}
              {!loading && <ShoppingCartOutlined style={{ color: 'white' }} />}
              {t('shell:addToCart')}
            </Button>
          </Container>
        </Container>
      </Card>
    </Col>
  );
};
