import * as React from 'react';
function SvgQuestionCircle(props) {
  return (
    <svg width={18} height={19} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M9 1.625a7.876 7.876 0 10.001 15.751A7.876 7.876 0 009 1.625zm0 14.414A6.54 6.54 0 019 2.961a6.54 6.54 0 010 13.078z"
        fill="url(#question-circle_svg__paint0_linear)"
      />
      <path
        d="M10.962 6.067A2.963 2.963 0 009 5.352c-.738 0-1.434.254-1.962.715-.548.48-.85 1.125-.85 1.816v.133c0 .078.063.141.14.141h.844c.077 0 .14-.063.14-.14v-.134c0-.775.758-1.406 1.688-1.406.93 0 1.688.63 1.688 1.406 0 .546-.387 1.047-.987 1.278a1.965 1.965 0 00-1.265 1.86v.377c0 .078.063.141.14.141h.844c.077 0 .14-.063.14-.14v-.4a.85.85 0 01.544-.787c1.037-.4 1.707-1.313 1.707-2.33.002-.69-.3-1.335-.85-1.815zm-2.665 7.3a.703.703 0 101.406 0 .703.703 0 00-1.406 0z"
        fill="url(#question-circle_svg__paint1_linear)"
      />
      <defs>
        <linearGradient id="question-circle_svg__paint0_linear" x1={1.125} y1={9.5} x2={16.875} y2={9.5} gradientUnits="userSpaceOnUse">
          <stop stopColor="#FCCB90" />
          <stop offset={1} stopColor="#D57EEB" />
        </linearGradient>
        <linearGradient id="question-circle_svg__paint1_linear" x1={6.188} y1={9.711} x2={11.811} y2={9.711} gradientUnits="userSpaceOnUse">
          <stop stopColor="#FCCB90" />
          <stop offset={1} stopColor="#D57EEB" />
        </linearGradient>
      </defs>
    </svg>
  );
}
export default SvgQuestionCircle;
