import React from 'react';
import style from './style.module.scss';
import classnames from 'classnames';

interface BurgerButtonProps {
  isHidden: boolean;
  toggleMenu: () => void;
}

const BurgerButton: React.FC<BurgerButtonProps> = ({ isHidden, toggleMenu }) => {
  return (
    <div className={style.menu} onClick={toggleMenu}>
      <span
        className={classnames(style.menuGlobal, style.menuTop, {
          [style.menuTopClick]: isHidden,
        })}
      ></span>
      <span
        className={classnames(style.menuGlobal, style.menuMiddle, {
          [style.menuMiddleClick]: isHidden,
        })}
      ></span>
      <span
        className={classnames(style.menuGlobal, style.menuBottom, {
          [style.menuBottomClick]: isHidden,
        })}
      ></span>
    </div>
  );
};

export default BurgerButton;
