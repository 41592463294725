import React, { useState, useEffect } from 'react';
import { Typography, Divider } from 'antd';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { Link as RoutLink, navigate } from 'gatsby';
import cx from 'classnames';
import { useLocation } from '@reach/router';
import { useSelector, useDispatch } from 'react-redux';
import style from './style.module.scss';
import { FontWeight, IconsType, LogoType, MarkedElementIcons } from '../../lib/constants/enums';
import { pathTo } from '../../lib/utils';
import { navigationData } from './Navigation/navigationData';
import NavItem from './Navigation/NavItem/NavItem';

import MarkedElement from '../MarkedElement/MarkedElement';
import Searcher from './Searcher/Searcher';
import SiteNavigation from './Navigation/Navigation';
import LangChanger from './LangChanger/LangChanger';
import Logo from '../Logo/Logo';
import BurgerButton from './BurgerButton/BurgerButton';
import SignIn from './SignIn/SignIn';
import { HeaderCart } from './Cart/Cart';
// import { Country } from './Country/Country';
import { Button } from '../components';
import CurrencySelect from './CurrencySelect/currency-select';

import { filterProjectsActionCreator, handleSearchActionCreator } from '../../redux/search/search.actions';

import { strapiSelector } from '../../redux/strapi/strapi.selectors';
import { searchSelector } from '../../redux/search/search.selector';

import { Container, AdaptiveLayout } from '../Layouts/Layouts';

interface HeaderProps {
  getSideMenuValue: (val: boolean) => void;
}

const Header: React.FC<HeaderProps> = ({ getSideMenuValue }) => {
  const { Link } = Typography;
  const { t } = useTranslation();
  const [isHidden, setHidden] = useState<boolean>(false);
  const location = useLocation();
  const {
    search: { value: searchValue, filteredProjects },
  } = useSelector(searchSelector);
  const {
    strapi: { projects },
  } = useSelector(strapiSelector);
  const dispatch = useDispatch();
  const pathName = location.pathname;
  const pathNameTrim = pathName.replace(/\\|\//g, '');

  useEffect(() => {
    if (pathNameTrim === 'search' && !searchValue && !filteredProjects) navigate(pathTo('/'));
  }, []);

  useEffect(() => {
    getSideMenuValue(isHidden);
  }, [isHidden]);

  const handleSearch = () => {
    if (pathNameTrim !== 'search') navigate(pathTo('/search'));
    if (projects && searchValue)
      dispatch(
        filterProjectsActionCreator(
          projects?.filter(
            project => project.pageInfo && project.pageInfo.title?.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())
          )
        )
      );
    dispatch(handleSearchActionCreator(''));
    setHidden(false);
  };

  const toggleMenu = () => {
    setHidden(isHidden => !isHidden);
  };

  const headerContent = (
    <div className={style.headerContentList}>
      <div className={style.headerTop}>
        <AdaptiveLayout onDesktop onTabletLarge onTabletMedium onMobile>
          <div className={cx(style.headerTopItem, style.logoWrap)}>
            <Logo type={LogoType.black} link />
          </div>
        </AdaptiveLayout>
        {/* <AdaptiveLayout onDesktop onTabletLarge onMobile>
          <div className={style.headerTopItem}>
            <MarkedElement icon={MarkedElementIcons.vector}>
              <Link className={style.headerTryIt} style={{ whiteSpace: 'nowrap' }}>
                {t('header:tryIt')}
              </Link>
            </MarkedElement>
          </div>
        </AdaptiveLayout> */}
        <AdaptiveLayout onDesktop onTabletLarge>
          <div className={style.headerTopItem}>
            <Searcher placeholder={t('header:searcher')} handleSearch={handleSearch} />
          </div>
        </AdaptiveLayout>
        {/* <AdaptiveLayout onDesktop onTabletLarge>
          <div className={style.headerTopItem}>
            <CurrencySelect />
            <LangChanger />
          </div>
        </AdaptiveLayout>
        <div className={style.headerTopItem}>
          <HeaderCart />
        </div> */}
        <AdaptiveLayout onDesktop onTabletLarge>
          <div className={style.headerTopItem}>
            <SignIn />
          </div>
        </AdaptiveLayout>
        {/* <div className={style.headerTopItem}>
          <RoutLink to={pathTo('/store')}>
            <Button title={t('header:store')} fontSize={16} fontWeight={FontWeight.bold} icon={IconsType.store} />
          </RoutLink>
        </div> */}
      </div>
      <Divider className={style.headerDivider} />
      <div className={style.headerBottom}>
        <SiteNavigation>
          {navigationData.map(nav => {
            return <NavItem key={nav.id} to={nav.path} title={t(`shell:navigation.${nav.name}`)} />;
          })}
        </SiteNavigation>
      </div>
    </div>
  );

  return (
    <div className={style.headerWrapper}>
      <header className={style.header}>
        <Container>
          <AdaptiveLayout onDesktop>{headerContent}</AdaptiveLayout>
          <AdaptiveLayout onTablet onMobile>
            <div className={style.headerMobileWrapper}>
              <Logo type={LogoType.black} link />
              {/* <AdaptiveLayout onDesktop onTabletLarge onTabletMedium>
                <div className={style.headerTryItWrap}>
                  <MarkedElement icon={MarkedElementIcons.vector}>
                    <Link className={style.headerTryIt} style={{ whiteSpace: 'nowrap' }}>
                      {t('header:tryIt')}
                    </Link>
                  </MarkedElement>
                </div>
              </AdaptiveLayout> */}
              <AdaptiveLayout onDesktop onTablet>
                <div className={style.searcherWrap}>
                  <Searcher placeholder={t('header:searcher')} handleSearch={handleSearch} />
                </div>
              </AdaptiveLayout>
              <AdaptiveLayout onTabletMedium onMobile>
                <div
                  className={classnames(style.headerMobile, {
                    [style.headerMobileActive]: isHidden,
                  })}
                >
                  {headerContent}
                </div>
              </AdaptiveLayout>
              <div className={style.langChangerWrapper}>
                {/* <Country /> */}
                {/* <CurrencySelect />
                <LangChanger />
                <AdaptiveLayout onDesktop onTabletLarge>
                  <HeaderCart />
                </AdaptiveLayout> */}
                {/* <AdaptiveLayout onMobile>
                  <div className={style.searcherWrap}>
                    <Searcher placeholder={t('header:searcher')} handleSearch={handleSearch} />
                  </div>
                </AdaptiveLayout> */}
                <SignIn />
                {/* <AdaptiveLayout onDesktop onTabletLarge>
                  <div style={{ marginLeft: '20px' }}>
                    <RoutLink to={pathTo('/store')}>
                      <Button title={t('header:store')} fontSize={16} fontWeight={FontWeight.bold} icon={IconsType.store} />
                    </RoutLink>
                  </div>
                </AdaptiveLayout> */}
              </div>
              <AdaptiveLayout onTabletMedium onMobile>
                <BurgerButton isHidden={isHidden} toggleMenu={toggleMenu} />
              </AdaptiveLayout>
            </div>
            <AdaptiveLayout onDesktop onTabletLarge>
              <Divider className={style.headerDivider} />
              <div className={style.headerBottom}>
                <SiteNavigation>
                  {navigationData.map(nav => {
                    return <NavItem key={nav.id} to={nav.path} title={t(`shell:navigation.${nav.name}`)} />;
                  })}
                </SiteNavigation>
              </div>
            </AdaptiveLayout>
          </AdaptiveLayout>
        </Container>
      </header>
    </div>
  );
};

export default Header;
