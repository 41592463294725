import * as React from 'react';
function SvgCloseOption(props) {
  return (
    <svg width={18} height={13} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M17.719.692H15.96a.376.376 0 00-.303.155L9 10.025 2.34.847A.376.376 0 002.04.692H.28A.188.188 0 00.13.99l8.264 11.393c.3.412.914.412 1.211 0L17.87.99a.187.187 0 00-.15-.298z"
        fill="#D6D6D6"
      />
    </svg>
  );
}
export default SvgCloseOption;
