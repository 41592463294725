import React, { useEffect, useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import remarkGfm from 'remark-gfm';
import ReactMarkdown from 'react-markdown';
import style from './style.module.scss';
import { useLocation } from '@reach/router';
import queryString from 'query-string';

const getUserCountryCode = async () => {
  const lsKey = 'country-code'

  if (localStorage.getItem(lsKey)) {
    return localStorage.getItem(lsKey)
  }

  return new Promise((resolve) => {
    fetch('https://ipinfo.io/json?token=0e0b6084fb2051')
      .then(function (response) {
        return response.json();
      })
      .then(function (payload) {
        const code = payload.country?.toUpperCase()
        resolve(code)
        localStorage.setItem(lsKey, code)
      });
  })
}

export const findLegalEntity = async (urlParamLegalEntity, setLegalEntity, legalEntities) => {
  if (!urlParamLegalEntity) {
    const code = await getUserCountryCode()

    const foundLegalEntity = legalEntities?.find(i => i.countries?.some(c => c.code === code));
    if (foundLegalEntity) {
      console.log('[foundLegalEntity by country code]: ', code, foundLegalEntity);

      setLegalEntity(foundLegalEntity);
      return
    }
  }

  const foundLegalEntity = legalEntities?.find(i => i.urlParam === urlParamLegalEntity);
  console.log('[foundLegalEntity]: ', foundLegalEntity);
  if (foundLegalEntity) {
    setLegalEntity(foundLegalEntity);
  } else {
    /** Set default (vexor trade) */
    setLegalEntity(legalEntities?.find(i => i.urlParam === 'vexor-trade'));
  }
};

const Copyright: React.FC = () => {
  const [legalEntity, setLegalEntity] = useState(null);
  const location = useLocation();

  const { allStrapiLegalEntities } = useStaticQuery(graphql`
    query getLegalEntitiesFooter {
      allStrapiLegalEntities {
        nodes {
          footer
          urlParam
          countries {
            code
          }
        }
      }
    }
  `);

  const legalEntities = allStrapiLegalEntities.nodes;

  useEffect(() => {
    const { legalEntity: urlParamLegalEntity } = queryString.parse(location.search);
    findLegalEntity(urlParamLegalEntity, setLegalEntity, legalEntities);
  }, [location, legalEntities]);

  return (
    <ul className={style.copyrightList}>
      <li className={style.copyrightItem}>© {new Date().getFullYear()}</li>
      <div className={style.copyrightItem}>
        <ReactMarkdown
          transformImageUri={img => `${process.env.STRAPI_API_URL}${img}`}
          children={legalEntity && legalEntity.footer ? legalEntity.footer : legalEntities[1].footer}
          remarkPlugins={[remarkGfm]}
        />
      </div>
    </ul>
  );
};

export default Copyright;
