import React from 'react';
import style from '../style.module.scss';
import { Link } from 'gatsby';

interface NavItemProps {
  title: string;
  to: string;
}

const NavItem: React.FC<NavItemProps> = ({ title, to }) => {
  return (
    <Link activeClassName={style.header__nav_item_active} className={style.header__nav_item} to={to}>
      {title}
    </Link>
  );
};

export default NavItem;
