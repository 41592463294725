import { useEffect } from 'react';
import { useMutation, useLazyQuery } from '@apollo/react-hooks';
import { CartItem } from '../common-interfaces';
import { PayformCurrency } from '../../graphql-types';
import { useSelector } from 'react-redux';
import { ShippingUpdateStatusSelector } from '../../redux/shipping/shipping.selector';
import { GET_COUNTRY_BY_ABV_REQUEST } from '../../api/api-apollo/query/country';
import { CALCULATE_FULFILLMENT_RATE_REQUEST } from '../../api/api-apollo/mutation/calculateFulfillmentRate';

export const useFulfillmentRate = (countryCode: string, cart: CartItem[], currency: PayformCurrency) => {
  const [currencyRequest, { data: countryApolloResponse }] = useLazyQuery(GET_COUNTRY_BY_ABV_REQUEST(countryCode));
  const [calculateFulFillmentRate, { data, loading, error }] = useMutation(CALCULATE_FULFILLMENT_RATE_REQUEST);
  const { updateStatus } = useSelector(ShippingUpdateStatusSelector);

  useEffect(() => {
    if (countryCode) currencyRequest();
  }, [countryCode]);

  useEffect(() => {
    if (countryCode && currency && cart.length > 0 && countryApolloResponse) {
      calculateFulFillmentRate({
        variables: {
          calculate: {
            currency,
            cart: cart.map(item => ({
              id: +item.product.id,
              quantity: item.quantity,
            })),
            countryId: Number(countryApolloResponse.CountryAbv.id),
          },
        },
      });
    }
  }, [countryCode, currency, cart, countryApolloResponse, updateStatus]);

  return { data, loading, error };
};
