import * as React from 'react';
function SvgVkontakte(props) {
  return (
    <svg width={29} height={29} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M14.724 0c-7.732 0-14 6.492-14 14.5s6.268 14.5 14 14.5c7.731 0 14-6.492 14-14.5S22.455 0 14.724 0zm7.101 16.068c.653.66 1.343 1.282 1.929 2.01a5.7 5.7 0 01.69 1.03c.266.534.026 1.12-.436 1.152H21.14c-.74.063-1.33-.247-1.827-.771-.397-.418-.765-.865-1.147-1.297a2.715 2.715 0 00-.517-.476c-.39-.263-.73-.183-.954.24-.229.431-.28.908-.303 1.387-.03.7-.235.884-.913.917-1.45.07-2.826-.158-4.105-.915-1.128-.668-2-1.61-2.762-2.677-1.481-2.08-2.616-4.364-3.635-6.713-.23-.529-.062-.812.502-.823a77.054 77.054 0 012.808 0c.38.006.633.231.78.604.505 1.289 1.125 2.515 1.902 3.653.208.303.419.605.72.819.332.236.585.158.742-.227.1-.244.144-.505.166-.767.074-.896.083-1.792-.046-2.684-.08-.559-.384-.92-.921-1.025-.274-.054-.234-.16-.1-.321.23-.28.447-.453.88-.453l3.24-.001c.511.104.626.341.695.874l.003 3.73c-.006.206.1.817.457.952.287.098.476-.14.647-.328.777-.854 1.33-1.862 1.827-2.905.218-.46.407-.937.59-1.414.137-.352.349-.526.733-.52l3.12.004c.093 0 .186 0 .277.017.526.093.67.327.508.859-.257.835-.754 1.53-1.24 2.227-.522.747-1.078 1.467-1.594 2.217-.474.685-.437 1.03.152 1.625z"
        fill="#A4CE41"
      />
      <path
        d="M14.724 0c-7.732 0-14 6.492-14 14.5s6.268 14.5 14 14.5c7.731 0 14-6.492 14-14.5S22.455 0 14.724 0zm7.101 16.068c.653.66 1.343 1.282 1.929 2.01a5.7 5.7 0 01.69 1.03c.266.534.026 1.12-.436 1.152H21.14c-.74.063-1.33-.247-1.827-.771-.397-.418-.765-.865-1.147-1.297a2.715 2.715 0 00-.517-.476c-.39-.263-.73-.183-.954.24-.229.431-.28.908-.303 1.387-.03.7-.235.884-.913.917-1.45.07-2.826-.158-4.105-.915-1.128-.668-2-1.61-2.762-2.677-1.481-2.08-2.616-4.364-3.635-6.713-.23-.529-.062-.812.502-.823a77.054 77.054 0 012.808 0c.38.006.633.231.78.604.505 1.289 1.125 2.515 1.902 3.653.208.303.419.605.72.819.332.236.585.158.742-.227.1-.244.144-.505.166-.767.074-.896.083-1.792-.046-2.684-.08-.559-.384-.92-.921-1.025-.274-.054-.234-.16-.1-.321.23-.28.447-.453.88-.453l3.24-.001c.511.104.626.341.695.874l.003 3.73c-.006.206.1.817.457.952.287.098.476-.14.647-.328.777-.854 1.33-1.862 1.827-2.905.218-.46.407-.937.59-1.414.137-.352.349-.526.733-.52l3.12.004c.093 0 .186 0 .277.017.526.093.67.327.508.859-.257.835-.754 1.53-1.24 2.227-.522.747-1.078 1.467-1.594 2.217-.474.685-.437 1.03.152 1.625z"
        fill="url(#Vkontakte_svg__paint0_linear)"
      />
      <defs>
        <linearGradient id="Vkontakte_svg__paint0_linear" x1={14.933} y1={0} x2={14.933} y2={29} gradientUnits="userSpaceOnUse">
          <stop stopColor="#D3E321" />
          <stop offset={1} stopColor="#fff" stopOpacity={0} />
        </linearGradient>
      </defs>
    </svg>
  );
}
export default SvgVkontakte;
