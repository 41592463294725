import React, { useEffect, useState, FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { navigate } from '@reach/router';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { CopyOutlined, LinkOutlined, LoadingOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Card, Input, notification, Spin, Typography } from 'antd';
import { ArgsProps } from 'antd/lib/notification';
import * as ISOCountries from 'i18n-iso-countries';
import { RootModel } from '../../redux/store';
import Container from '../Layouts/Container/Container';
import { CheckoutConfirm } from '../../modules/SharedModules/CheckoutConfirm/CheckoutConfirm';
import { ClearOrderActionCreator, SaveOrderActionCreator } from '../../redux/order/order.actions';
import { itemDiscountTotal, pathTo, toDecimals, CurrencySymbols, fixedNum } from '../../lib/utils';
import { useRates } from '../../lib/hooks/rates';
import { CurrencySelector } from '../../redux/currency/currency.selector';
import { PayformCurrency } from '../../lib/constants';
import { useFulfillmentRate } from '../../lib/hooks/useFulfillmentRate';
import { PayformDiscountCartRequestDto } from '../../graphql-types';
import { ShippingMethod } from '../../lib/common-interfaces';
import { usePromocode } from '../../lib/hooks/usePromocode';

import css from './carttotals.module.scss';
// eslint-disable-next-line @typescript-eslint/no-var-requires
ISOCountries.registerLocale(require('i18n-iso-countries/langs/en.json'));
// eslint-disable-next-line @typescript-eslint/no-var-requires
ISOCountries.registerLocale(require('i18n-iso-countries/langs/ru.json'));

export interface CartTotalsProps {
  page: 'cart' | 'checkout';
}

export const CartTotals: FC<CartTotalsProps> = ({ page }) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const isXS = useMediaQuery({ maxWidth: 480 });
  const { convert, rates } = useRates();
  const { currency } = useSelector(CurrencySelector);

  const applyButton = isXS ? <SearchOutlined /> : t('shell:cart.cartTotals.applyPromocode');

  const { loading, cart, order, shippingSelected, link, countryCode, manager, showManager, savedFields } = useSelector(
    (state: RootModel) => ({
      loading: state.cart.status.loading,
      cart: state.cart.cart,
      order: state.order.order,
      shippingSelected: state.shipping.selected,
      link: state.order.link,
      countryCode: state.geocode.countryCode,
      manager: state.order.manager,
      showManager: state.order.showManager,
      savedFields: state.order.savedFields,
    })
  );

  const { data, loading: shippingLoading } = useFulfillmentRate(countryCode || '', cart, currency);
  const [isConfirmVisible, updateConfirmVisible] = useState<boolean>(false);
  const [promocode, updatePromocode] = useState<string | null | undefined>();
  const [promocodeToCheck, updatePromocodeToCheck] = useState<string | undefined>();
  const [promocodeChecked, updatePromocodeChecked] = useState<boolean>(false);
  const getCart: PayformDiscountCartRequestDto[] = cart.map(item => ({ productId: Number(item.product.id), quantity: item.quantity }));

  const { data: discountResponse, loading: discountLoading } = usePromocode(
    {
      code: promocodeToCheck as string,
      cart: getCart,
      currency: [currency],
    },
    promocodeChecked
  );

  useEffect(() => {
    if (order) {
      dispatch(ClearOrderActionCreator());
    }
  }, []);

  useEffect(() => {
    if (discountResponse || discountLoading) updatePromocodeChecked(true);
  }, [discountResponse, discountLoading]);

  const loadingStyle: React.CSSProperties = loading ? { filter: 'blur(4px)' } : {};

  // TODO create custom hook for those functions
  const evaluateSubTotal = (): number => {
    let total = 0;
    cart.forEach(item => {
      if (item.product.prices.length > 0 && rates) {
        const { amount, currency: productCurrency } = item.product.prices[0].list![0];
        const convertedPrice = convert(amount, productCurrency, currency);
        total += convertedPrice * item.quantity;
      }
    });
    return total;
  };

  const evaluateDiscountTotal = (): number => {
    let total = 0;
    cart.forEach(item => {
      total += itemDiscountTotal(item, currency) * item.quantity;
    });
    return total;
  };

  const subTotal: number = toDecimals(evaluateSubTotal());
  const discount: number = toDecimals(evaluateDiscountTotal());
  const shipping: number = shippingSelected ? toDecimals(convert(shippingSelected?.price, PayformCurrency.Euro, currency)) : 0;
  const total: number = toDecimals(subTotal - discount + shipping);

  const getShareableLink = () => {
    if (savedFields) {
      dispatch(SaveOrderActionCreator(savedFields));
    }
  };

  const checkPromocode = () => {
    if (promocode) {
      updatePromocodeToCheck(promocode);
      updatePromocodeChecked(false);
    }
  };

  const shippingAvailable = () => {
    const countryName = countryCode ? ISOCountries.getName(countryCode, i18n.language) : undefined;
    if (data && data.CalculateFulFillmentRate && data.CalculateFulFillmentRate.methods.length === 0) {
      return t('checkout:shipping.notAvailable');
    }
    if (data && data.CalculateFulFillmentRate && data.CalculateFulFillmentRate.methods.length > 0 && countryName) {
      const cheapShipping = data.CalculateFulFillmentRate.methods.reduce((acc: ShippingMethod, curr: ShippingMethod) => {
        return acc.price > curr.price ? curr : acc;
      });
      return `${t('shell:cart.cartTotals.shippingCalc.shippingTo')} ${countryName} ${t('shell:cart.cartTotals.shippingCalc.from')}  ${t(
        `shell:currencies.${cheapShipping.currency}`
      )} ${convert(cheapShipping.price, PayformCurrency.Euro, currency).toFixed(2)}`;
    }
  };

  return (
    <>
      <Card className={css.card} title={t('shell:cart.total')}>
        <Container className={css.totalsContainer}>
          <Typography.Text>{`${t('shell:cart.cartTotals.subtotal')}:`}</Typography.Text>
          <Typography.Text style={loadingStyle}>
            {CurrencySymbols[currency]} {fixedNum(subTotal)}
          </Typography.Text>
        </Container>

        <Container className={css.totalsContainer}>
          <Typography.Text>{`${t('shell:cart.cartTotals.promocode')}:`}</Typography.Text>
          <Input
            onChange={e => {
              updatePromocode(e.target.value);
            }}
            style={{ maxWidth: '100px' }}
          />
          <Button type="default" disabled={!promocode || promocode.length === 0} onClick={() => checkPromocode()}>
            {loading ? <LoadingOutlined /> : applyButton}
          </Button>
        </Container>

        {discount > 0 && (
          <Container className={css.totalsContainer}>
            <Typography.Text>{`${t('shell:cart.cartTotals.discount')}:`}</Typography.Text>
            <Typography.Text style={loadingStyle}>&euro; {discount.toFixed(2)}</Typography.Text>
          </Container>
        )}
        {shippingSelected && (
          <Spin spinning={shippingLoading} indicator={<LoadingOutlined style={{ fontSize: '24px' }} />}>
            <Container className={css.totalsContainer}>
              <Typography.Text style={{ maxWidth: '20ch' }}>{`${t('shell:cart.cartTotals.shipping')}(${
                shippingSelected.name
              }):`}</Typography.Text>
              <Typography.Text style={loadingStyle}>
                {t(`shell:currencies.${shippingSelected.currency}`)}{' '}
                {rates && currency && convert(shippingSelected.price, PayformCurrency.Euro, currency).toFixed(2)}
              </Typography.Text>
            </Container>
          </Spin>
        )}
        <Container className={css.totalsContainer}>
          <Typography.Text strong>{`${t('shell:cart.cartTotals.total')}:`}</Typography.Text>
          <Typography.Text strong style={loadingStyle}>
            {CurrencySymbols[currency]} {fixedNum(total)}
          </Typography.Text>
        </Container>
        {page === 'cart' && (
          <Spin spinning={shippingLoading} indicator={<LoadingOutlined style={{ fontSize: '24px' }} />}>
            <Container className={css.shippingContainer}>
              <Typography.Text>{shippingAvailable()}</Typography.Text>
              {!data && <Typography.Text className={css.moreinfo}>{t('shell:cart.cartTotals.shippingCalc.moreinfo')}</Typography.Text>}
            </Container>
          </Spin>
        )}
        <Container className={css.totalsContainer}>
          <Button
            type="primary"
            size="large"
            block
            onClick={() => (page === 'cart' ? navigate(pathTo('checkout')) : updateConfirmVisible(true))}
            disabled={loading || cart.length === 0 || total < 0 || (page === 'checkout' && !order)}
          >
            {t('shell:cart.checkout')}
          </Button>
        </Container>
        {page === 'checkout' && manager && showManager && (
          <Container className={css.totalsContainer}>
            <Button
              type="ghost"
              size="small"
              onClick={() => getShareableLink()}
              disabled={
                loading ||
                cart.length === 0 ||
                total <= 0 ||
                (page === 'checkout' && !savedFields) ||
                (page === 'checkout' && !savedFields?.user?.firstName && !savedFields?.user?.lastName)
              }
            >
              {loading ? <LoadingOutlined /> : <LinkOutlined style={{ color: '#d9d9d9' }} />}
              {t('shell:cart.share')}
            </Button>
            {link && (
              <Input
                size="small"
                value={link}
                style={{ marginLeft: '5px' }}
                addonAfter={
                  <CopyOutlined
                    onClick={() => {
                      const notificationOptions: ArgsProps = {
                        placement: 'topRight',
                        message: t('shell:order.messages.link'),
                      };
                      navigator.clipboard.writeText(link).then(() => notification.info(notificationOptions));
                    }}
                  />
                }
              />
            )}
          </Container>
        )}
      </Card>
      <CheckoutConfirm visible={isConfirmVisible} onCancel={() => updateConfirmVisible(false)} />
    </>
  );
};
