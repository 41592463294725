import React from 'react';
import { Link } from 'gatsby';
import { useTranslation } from 'react-i18next';
import style from './style.module.scss';
import { chunkArray } from '../../../../lib/utils/index';
import { navData } from './navData';
import Adaptivelayout from '../../../Layouts/AdaptiveLayout/AdaptiveLayout';

const FooterNav: React.FC = () => {
  const { t } = useTranslation();

  const renderNav = data => {
    const mapData = data.map((navArray, index) => {
      return (
        <nav className={style.footerNav} key={index}>
          {navArray.map(el => {
            return (
              <React.Fragment key={el.id}>
                {el.link ? (
                  <a className={style.footerNavLink} href={el.link} key={el.id} target="_blank">
                    {t(`shell:navigation.${el.name}`)}
                  </a>
                ) : (
                  <Link className={style.footerNavLink} to={el.path} key={el.id}>
                    {t(`shell:navigation.${el.name}`)}
                  </Link>
                )}
              </React.Fragment>
            );
          })}
        </nav>
      );
    });
    return mapData;
  };

  return (
    <div className={style.footerNavWrapper}>
      <Adaptivelayout onDesktop>{renderNav(chunkArray(navData, 5))}</Adaptivelayout>
      <Adaptivelayout onTablet>{renderNav(chunkArray(navData, 4))}</Adaptivelayout>
      <Adaptivelayout onMobile>{renderNav(chunkArray(navData, navData.length))}</Adaptivelayout>
    </div>
  );
};

export default FooterNav;
