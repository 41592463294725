import React from 'react';
import style from './style.module.scss';
import { useTranslation } from 'react-i18next';
import { Typography } from 'antd';
import { socialData } from './socialData';
import ListIcons from '../../../ListIcons/ListIcons';
// import MarkedElement from '../../../MarkedElement/MarkedElement';
// import { MarkedElementIcons } from '../../../../lib/constants/enums';

const Social: React.FC = () => {
  const { t } = useTranslation();
  const { Text, Link } = Typography;
  return (
    <div className={style.social}>
      <Text className={style.description}>{t(`footer:social.description`)}</Text>
      <div className={style.socialIconList}>
        {socialData.map(iconKey => {
          return <ListIcons itemKey={iconKey} key={iconKey} />;
        })}
      </div>
      {/* <MarkedElement icon={MarkedElementIcons.vector}>
        <Link className={style.footerLink}>{t('header:tryIt')}</Link>
      </MarkedElement> */}
    </div>
  );
};

export default Social;
