import React from 'react';
import style from './style.module.scss';
import Logo from '../../Logo/Logo';
import { LogoType } from '../../../lib/constants/enums';
import FooterNav from './navigation';
import Contact from './contact';
import Social from './social/Social';

const TopFooterContent: React.FC = () => {
  return (
    <div className={style.footerTopContent}>
      <div className={style.logoWrapper}>
        <Logo type={LogoType.white} />
      </div>
      <div className={style.contentList}>
        <FooterNav />
        <Contact />
        <Social />
      </div>
    </div>
  );
};

export default TopFooterContent;
