import { useState, useEffect } from 'react';
// import { message } from 'antd';
import { useQuery } from '@apollo/react-hooks';
import { PayformCurrency } from '../../graphql-types';
import { EXCHANGE_RATE_REQUEST } from '../../api/api-apollo/query/rate';

export interface Rates {
  dollar?: number;
  euro?: number;
  ruble?: number;
  shekel?: number;
}

export const useRates = () => {
  const [rates, updateRates] = useState<Rates>();
  const { data, error } = useQuery(EXCHANGE_RATE_REQUEST);

  // Get current currency rates
  useEffect(() => {
    if (data && !error) {
      const { ExchangeRate } = data;
      const newRates = ExchangeRate.map(({ currency, rate }) => [currency, rate]);
      updateRates({ ...Object.fromEntries(newRates) });
      return;
    }
    if (error) {
      // message.error(error.message);
    }
  }, [data, error]);

  const convert = (amount: number, inputCurrency: PayformCurrency, outputCurency: PayformCurrency): number => {
    if (rates) {
      const priceInEuros = amount / rates[inputCurrency];
      return Math.round(((priceInEuros * rates[outputCurency]) as number) * 100) / 100;
    }
  };

  return { rates, convert };
};
