import React, { useState } from 'react';
import style from './style.module.scss';
import { CloseOption } from '../../../Icons';
import cx from 'classnames';

interface TaplinkOptionProps {
  label: string;
  description: string;
}

const TaplinkOption: React.FC<TaplinkOptionProps> = ({ label, description }) => {
  const [isDescriptionView, setDescription] = useState(false);
  return (
    <div className={style.option}>
      <div className={style.label} onClick={() => setDescription(prev => !prev)}>
        <h2>{label}</h2>
        <div className={cx(style.btnSquare, { [style.activebtnSquare]: isDescriptionView })}>
          <div className={style.closeBg} />
          <span className={style.close} />
        </div>
      </div>
      {isDescriptionView && (
        <div className={style.description}>
          <div className={style.descriptionHead} onClick={() => setDescription(false)}>
            <h2>Описание</h2>
            <CloseOption />
          </div>
          <p>{description}</p>
        </div>
      )}
    </div>
  );
};

export default TaplinkOption;
