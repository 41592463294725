import React, { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { Typography, Image, Divider } from 'antd';
import { Link } from 'gatsby';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import cx from 'classnames';
import ReactMarkdown from 'react-markdown';
import ReactPlayer from 'react-player';
import style from './style.module.scss';
import { CardItemType, FontWeight, ButtonType } from '../../lib/constants/enums';
import { PayformProductResponseDto } from '../../graphql-types';
import { pathTo, CurrencySymbols, fixedNum } from '../../lib/utils';

import { CurrencySelector } from '../../redux/currency/currency.selector';
import { useRates } from '../../lib/hooks/rates';
import Button from '../Button/Button';
import { ReviewIcon } from '../../Icons';
import remarkGfm from 'remark-gfm';

const productImageStub = require('../../assets/icons/product-image-stub.svg');

interface Card {
  name: CardItemType;
  card: ReactNode;
}

type CardArray = Array<Card>;

interface CardItemProps {
  image?: string;
  title?: string;
  description?: string;
  link?: string;
  slug?: string;
  date?: string;
  type?: CardItemType;
  width?: string;
  details?: PayformProductResponseDto;
  isTraingle?: boolean;
  btnTitle?: string;
  btnType?: ButtonType;
  video?: {
    poster: string;
    src: string;
    link: string;
  };
  last?: boolean;
}

const CardItem: React.FC<CardItemProps> = props => {
  const {
    image,
    title,
    description,
    link,
    date,
    type = CardItemType.direction,
    width = '100%',
    details,
    isTraingle,
    btnTitle,
    btnType,
    video,
    last = false,
  } = props;
  const { t, i18n } = useTranslation();
  const { Title, Text } = Typography;
  const { currency } = useSelector(CurrencySelector);
  const [price, setPrice] = useState<number>(0);
  const { convert, rates } = useRates();
  useEffect(() => {
    if (details && rates) {
      details.prices.map(price => {
        price.list?.map(it => {
          setPrice(convert(it.amount, it.currency, currency));
        });
      });
    }
  }, [details, rates, currency]);

  // const ImageCard = <Image src={image} className={style.cardImage} alt={slug} fallback={productImageStub} />;

  const directionCard = (
    <>
      <div className={style.directionImage}>
        <img src={image} className={style.cardImage} />
      </div>
      <div className={style.cardInfo}>
        <Title level={4}>{title}</Title>
        <Text className={style.description}>{description}</Text>
        <div className={style.button}>
          {link && link.includes('http') ? (
            <a className={style.btnLink} href={link || '#'} target="_blank">
              <Button type={btnType && btnType} uppercase fontWeight={FontWeight.bold} border title={btnTitle || t('shell:cardItem.buy')} />
            </a>
          ) : (
            <Link className={style.btnLink} to={link || '#'}>
              <Button type={btnType && btnType} uppercase fontWeight={FontWeight.bold} border title={btnTitle || t('shell:cardItem.buy')} />
            </Link>
          )}
        </div>
      </div>
    </>
  );

  const descriptionCard = (
    <>
      <Title level={4} className={style.title}>
        {title}
      </Title>
      <Text className={style.info}>{description}</Text>
    </>
  );

  const postCard = (
    <>
      <div className={style.directionImage}>
        {' '}
        <img src={image} className={style.cardImage} />
      </div>
      <div className={style.cardInfo}>
        <Title level={4}>{title}</Title>
        <Text className={style.date}>{date}</Text>
        <a className={style.link} href={link || '#'}>
          {t('shell:cardItem.readMore')}
        </a>
      </div>
    </>
  );

  const awardCard = (
    <>
      <div className={style.awardImage}>
        {!image ? (
          <Image className={style.cardImage} fallback={productImageStub} />
        ) : (
          <Image src={`${process.env.STRAPI_API_URL}${image}`} className={style.cardImage} />
        )}
      </div>
      <div className={style.awardInfo}>
        <div className={style.awardDescription}>
          <ReactMarkdown transformImageUri={img => `${process.env.STRAPI_API_URL}${img}`} children={description || ''} remarkPlugins={[remarkGfm]} />
        </div>
      </div>
    </>
  );

  const featuredCard = (
    <Link to={pathTo(`/product/${details?.slug}`)}>
      <div className={style.featuredImage}>
        <Image
          src={details?.photos && details.photos.length !== 0 ? details.photos[0] : ''}
          className={style.cardImage}
          alt={details?.slug}
          fallback={productImageStub}
        />
      </div>
      <div className={style.infoWrapper}>
        <Title title={details?.name[i18n.language as 'en' | 'ru']} level={5}>
          {details?.name[i18n.language as 'en' | 'ru']}
        </Title>
        <Divider className={style.divider} />
        <Text>
          {t('shell:cardItem.currency')}: <Text strong>{`${fixedNum(price)} ${CurrencySymbols[currency]}`}</Text>
        </Text>
      </div>
    </Link>
  );

  const reviewCard = (
    <div className={style.reviewWrapper}>
      <div className={style.reviewContent}>
        <div className={cx(style.reviewIcon, style.reviewIconLeft)}>
          <ReviewIcon />
        </div>
        <div className={cx(style.reviewIcon, style.reviewIconRight)}>
          <ReviewIcon />
        </div>
        <h3 className={style.reviewTitle}>{title}</h3>
        <div className={style.reviewDescription}>
          <ReactMarkdown transformImageUri={img => `${process.env.STRAPI_API_URL}${img}`} children={description || ''} remarkPlugins={[remarkGfm]} />
        </div>
      </div>
      {isTraingle && <div className={style.reviewTriangle} />}
    </div>
  );

  const taplinkVideoBlock = (
    <div className={style.taplinkVideoWrapper}>
      <h2 className={style.taplinkVideoTitle}>{title}</h2>
      {video?.link ? (
        <iframe className={style.videoBlockFrame} src={video.link} width="100%" frameBorder="0" allow="autoplay; fullscreen" />
      ) : (
        <ReactPlayer
          url={`${process.env.STRAPI_API_URL}${video?.src}`}
          light={`${process.env.STRAPI_API_URL}${video?.poster}`}
          width="100%"
          height="220px"
          controls
          playIcon={<div className={style.playBtn} />}
        />
      )}
      <div className={style.taplinkVideoDescription}>
        <ReactMarkdown transformImageUri={img => `${process.env.STRAPI_API_URL}${img}`} children={description || ''} remarkPlugins={[remarkGfm]} />
      </div>
    </div>
  );

  const cardTypeArray: CardArray = [
    {
      name: CardItemType.direction,
      card: directionCard,
    },
    {
      name: CardItemType.description,
      card: descriptionCard,
    },
    {
      name: CardItemType.post,
      card: postCard,
    },
    {
      name: CardItemType.awards,
      card: awardCard,
    },
    {
      name: CardItemType.featured,
      card: featuredCard,
    },
    {
      name: CardItemType.review,
      card: reviewCard,
    },
    {
      name: CardItemType.taplinkVideo,
      card: taplinkVideoBlock,
    },
  ];

  const findCart: Card | undefined = cardTypeArray.find(card => card.name === type);
  return (
    <div style={{ width }} className={classnames(style.cardItem, style[type], { [style.lastAward]: last })}>
      {findCart && findCart.card}
    </div>
  );
};

export default CardItem;
