import React, { useState } from 'react';
import style from './style.module.scss';
import { Input } from 'antd';
import { useMediaQuery } from 'react-responsive';
import { SearchOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { colors } from '../../../styles/variables';
import { useDispatch, useSelector } from 'react-redux';
import { handleSearchActionCreator } from '../../../redux/search/search.actions';
import { searchSelector } from '../../../redux/search/search.selector';
import cx from 'classnames';

interface SearcherProps {
  handleSearch: () => void;
  placeholder: string;
}

const Searcher: React.FC<SearcherProps> = ({ handleSearch, placeholder }) => {
  const { Search } = Input;
  const isMd = useMediaQuery({ minWidth: 1150 });
  const dispatch = useDispatch();
  const {
    search: { value },
  } = useSelector(searchSelector);
  const [isViewInput, setViewInput] = useState<boolean>(false);

  const onChangeSearch = (value: string) => dispatch(handleSearchActionCreator(value));

  return (
    <>
      {!isMd ? (
        <div className={style.searchBtnWrap}>
          <div
            className={style.searchBtn}
            onClick={() => {
              setViewInput(prev => !prev);
              if (value) handleSearch();
            }}
          >
            {isViewInput ? (
              <ArrowRightOutlined style={{ color: colors.white }} />
            ) : (
              <SearchOutlined className={style.searchIcon} style={{ color: colors.white }} />
            )}
          </div>
          <input
            onChange={e => onChangeSearch(e.target.value)}
            className={cx(style.hiddenSearch, { [style.searchInputView]: isViewInput })}
            type="text"
            value={value}
          />
        </div>
      ) : (
        <div className={style.searcherWrapper}>
          <Search
            onChange={e => onChangeSearch(e.target.value)}
            className={style.searcher}
            placeholder={placeholder}
            onSearch={handleSearch}
            value={value}
            enterButton
          />
        </div>
      )}
    </>
  );
};

export default Searcher;
