import React from 'react';
import { Typography } from 'antd';
import { listIconsData } from './data';
import { StrapiTaplinksTaplinkBlocksSocialItem } from '../../graphql-types';

interface ListIconsProps {
  className?: string;
  itemKey: string;
  width?: string;
  height?: string;
  socialIcon?: StrapiTaplinksTaplinkBlocksSocialItem | null | undefined;
}

const ListIcons: React.FC<ListIconsProps> = ({ className, itemKey, width, height, socialIcon }) => {
  const { Link } = Typography;
  const { Icon, link } = listIconsData[itemKey];
  return (
    <>
      {socialIcon && socialIcon.iconKey === itemKey ? (
        <Link className={className || ''} href={socialIcon.link || link} target="_blank">
          <Icon viewBox={'0 0 30 30'} width={(width && width) || '30px'} height={(height && height) || '30px'} />
        </Link>
      ) : (
        <Link className={className || ''} href={link} target="_blank">
          <Icon viewBox={'0 0 30 30'} width={(width && width) || '30px'} height={(height && height) || '30px'} />
        </Link>
      )}
    </>
  );
};

export default ListIcons;
