import gql from 'graphql-tag';

export const CHECK_PROMOCODE_REQUEST = gql`
  mutation CalculatedDiscount($request: DiscountCalculateWithCartRequestDto!) {
    DiscountCalculated(request: $request) {
      id
      title
      products {
        id
        totals {
          amount
          total
          currency
        }
      }
    }
  }
`;
