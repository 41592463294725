import React, { useState } from 'react';
import style from './style.module.scss';

interface DotsMenuProps {
  children: JSX.Element[];
}

const DotsMenu: React.FC<DotsMenuProps> = ({ children }) => {
  const [isVisible, setVisible] = useState<boolean>(false);
  return (
    <>
      {(children.length !== 0 && (
        <div className={style.dotsMenuWrap} onClick={() => setVisible(prev => !prev)}>
          <div className={style.dotsMenu}>
            <div className={style.dot} />
            <div className={style.dot} />
            <div className={style.dot} />
          </div>
          {isVisible && <div className={style.dotsPopup}>{children}</div>}
        </div>
      )) ||
        null}
    </>
  );
};

export default DotsMenu;
