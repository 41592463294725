import { NavigationPath, NavigationName } from '../../../lib/constants/enums';
import { NavigationData } from '../../../lib/common-interfaces';

export const navigationData: NavigationData = [
  {
    name: NavigationName.about,
    path: NavigationPath.about,
    id: 1,
  },
  {
    name: NavigationName.slimmingSchool,
    path: NavigationPath.slimmingSchool,
    id: 2,
  },
  // {
  //   name: NavigationName.academyOfNutrition,
  //   path: NavigationPath.academyOfNutrition,
  //   id: 3,
  // },
  {
    name: NavigationName.faceFitness,
    path: NavigationPath.faceFitness,
    id: 10,
  },
  // {
  //   name: NavigationName.onlineProfessionAcademy,
  //   path: NavigationPath.promotionAcademy,
  //   id: 11,
  // },
  // {
  //   name: NavigationName.fitnessEquipment,
  //   path: NavigationPath.fitnessEquipment,
  //   id: 4,
  // },
  {
    name: NavigationName.blog,
    path: NavigationPath.blog,
    id: 7,
  },
  // {
  //   name: NavigationName.FAQ,
  //   path: NavigationPath.FAQ,
  //   id: 8,
  // },
  // {
  //   name: NavigationName.promotionAcademy,
  //   path: NavigationPath.promotionAcademy,
  //   id: 5,
  // },
  // {
  //   name: NavigationName.reviews,
  //   path: NavigationPath.reviews,
  //   id: 6,
  // },
  // {
  //   name: NavigationName.contacts,
  //   path: NavigationPath.contacts,
  //   id: 9,
  // },
];
