import gql from 'graphql-tag';

export const CALCULATE_FULFILLMENT_RATE_REQUEST = gql`
  mutation CalculateFulFillmentRate($calculate: FulfillmentRateCalculateRequestDto!) {
    CalculateFulFillmentRate(calculate: $calculate) {
      methods {
        deliveryMethodId
        packagesCount
        name
        type
        price
        currency
        daysMax
        daysMin
      }
    }
  }
`;
