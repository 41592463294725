import { RootModel } from '../store';

export const CartSelector = (store: RootModel) => ({
  loading: store.cart.status.loading,
  cart: store.cart.cart,
});

export const CartStatusSelector = (state: RootModel) => ({
  loading: state.cart.status.loading,
  clickedId: state.cart.status.clickedId,
});

export const CartCheckoutSelector = (state: RootModel) => ({
  loading: state.order.status.loading,
  cart: state.cart.cart,
  order: state.order.order,
  countryCode: state.geocode.countryCode,
});
