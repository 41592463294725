import React, { useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';
import { Location, useLocation } from '@reach/router';
import { useSelector, useDispatch } from 'react-redux';
import Header from '../../Header/Header';
import Container from '../Container/Container';
import ScrollToTop from '../ScrollToTop/ScrollToTop';
import { LangProvider, LangKey } from '../LangContext/LangContext';
import css from './index.module.scss';
import Footer from '../../Footer/Footer';
import '../../../i18n';
import GQL, { StrapiArticles } from '../../../graphql-types';
import { strapiSelector } from '../../../redux/strapi/strapi.selectors';
import * as strapiAction from '../../../redux/strapi/strapi.actions';
import SeoModule from '../../../modules/SharedModules/SeoModule/SeoModule';

export interface StaticQueryProps {
  site: {
    siteMetadata: {
      title: string;
      description: string;
      keywords: string;
      languages: {
        defaultLanguage: LangKey;
        languages: LangKey[];
      };
    };
  };
  categories: GQL.PayformProductsCategories;
}

export interface LayoutProps {
  location?: { pathname: string };
  children: React.ReactNode;
  article?: StrapiArticles | undefined;
}

export const HeaderContext = React.createContext<StaticQueryProps | null>(null);

const IndexLayout: React.FC<LayoutProps> = ({ children, article }) => {
  const [hidden, setHidden] = useState<boolean>(false);
  const dispatch = useDispatch();
  const { strapi } = useSelector(strapiSelector);
  const location = useLocation();

  useEffect(() => {
    if (!strapi.pages) dispatch(strapiAction.getMainPagesActionCreator());
    if (!strapi.projects) dispatch(strapiAction.getProjectPagesActionCreator());
    if (!strapi.blogCategories) dispatch(strapiAction.getBlogCategoriesActionCreator());
    if (!strapi.products) dispatch(strapiAction.getProductsActionCreator());
    if (!strapi.articles) dispatch(strapiAction.geArticlestActionCreator());
    if (!strapi.feedback) dispatch(strapiAction.getFeedbacktActionCreator());
  }, []);

  useEffect(() => {
    setHidden(false);
    if (location.origin === process.env.REDIRECT_URL) setHidden(true);
  }, [location]);

  const getSideMenuValue = (val: boolean) => {
    val ? (document.body.style.overflowY = 'hidden') : (document.body.style.overflowY = 'visible');
  };

  return (
    <StaticQuery
      query={graphql`
        fragment PageFragment on ContentfulPage {
          slug
          # metaTitle
          # metaKeywords
          # metaDescription
          title
          content {
            childMarkdownRemark {
              html
            }
          }
          node_locale
        }

        query IndexLayoutQuery {
          site {
            siteMetadata {
              title
              description
              keywords
              languages {
                defaultLanguage
                languages
              }
            }
          }

          # TODO THINK HOW RECURSIVELY CALL CHILDREN LEVELS

          # categories: PAYFORM {
          #   ProductCategories {
          #     id
          #     slug
          #     name {
          #       en
          #       ru
          #     }
          #     children {
          #       id
          #       slug
          #       name {
          #         en
          #         ru
          #       }
          #       children {
          #         id
          #         slug
          #         name {
          #           en
          #           ru
          #         }
          #       }
          #     }
          #   }
          # }
          # contentfulPage(slug: { eq: "catalog" }, node_locale: { eq: "ru-RU" }) {
          #  title
          # }
        }
      `}
      render={(data: StaticQueryProps) => {
        return (
          <HeaderContext.Provider value={data}>
            <SeoModule article={article} />
            <Location>
              {({ location }) => (
                // <Auth0Provider domain={process.env.AUTH0_DOMAIN} clientId={process.env.AUTH0_CLIENT_ID} redirectUri={location.origin}>
                <LangProvider
                  pathname={location.pathname}
                  langs={{
                    languages: data.site.siteMetadata.languages.languages,
                    defaultLangKey: data.site.siteMetadata.languages.defaultLanguage,
                  }}
                >
                  <ScrollToTop location={location} />
                  <Container className={css.mainWrapper}>
                    <Helmet
                      title={data.site.siteMetadata.title}
                      meta={[
                        { name: 'keywords', content: data.site.siteMetadata.keywords },
                        { name: 'google-site-verification', content: '5-Nf_IrAdj3lRhyBlgTfFI0XmHw7zR9SuY4ieOmEn-I' },
                        { name: 'yandex-verification', content: '1ac2802157c58d6a' },
                      ]}
                    />
                    <Container className={css.container}>{!hidden && <Header getSideMenuValue={getSideMenuValue} />}</Container>
                    <Container className={css.body}>{children}</Container>
                    {!hidden && <Footer />}
                  </Container>
                </LangProvider>
                // </Auth0Provider>
              )}
            </Location>
          </HeaderContext.Provider>
        );
      }}
    />
  );
};

export default IndexLayout;
