import React, { useEffect, useState } from 'react';
import css from '../style.module.scss';

const Toc: React.FC = () => {
  const [tocList, setTocList] = useState<object[]>([]);

  useEffect(() => {
    const elements = Array.from(document.querySelectorAll('h2, h3, h4'));

    if (elements.length > 0 && tocList.length === 0) {
      const list: object[] = [];

      elements.forEach((item: any, index) => {
        const { innerText: title } = item;

        const obj: { id: string; title: string; items: object[] } = {
          id: index + item.nodeName,
          title,
          items: [],
        };

        if (item.nodeName === 'H2') {
          list.push(obj);
        }
        if ((item.nodeName === 'H3' || item.nodeName === 'H4') && list.length > 0) {
          list[list.length - 1].items.push(obj);
        }
      });

      setTocList(list);
    }
  }, []);

  const createToc = (arr: object[]) => {
    return arr.map((elem: any) => {
      return (
        <li key={elem.id}>
          {elem.title}
          {elem.items.length > 0 ? <ul>{createToc(elem.items)}</ul> : false}
        </li>
      );
    });
  };

  return <ul className={css.transparent}>{createToc(tocList)}</ul>;
};

export default Toc;
