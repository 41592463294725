import React, { useEffect, useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { globalHistory } from '@reach/router';
import { useSelector } from 'react-redux';
import { withPrefix } from 'gatsby';
import { strapiSelector } from '../../../redux/strapi/strapi.selectors';

import Toc from './Toc/Toc';
import Bread from './Bread/Bread';
import { StrapiArticles } from '../../../graphql-types';

interface BaseInfo {
  url: string[];
  current: any;
}

interface Meta {
  title?: string;
  description: string;
  toc: boolean;
  bread: boolean;
}

interface SeoModuleProps {
  article: StrapiArticles | undefined;
}

const SeoModule: React.FC<SeoModuleProps> = ({ article }) => {
  const {
    strapi: { pages, projects, politics, blogCategories, products, articles },
  } = useSelector(strapiSelector);

  const [baseInfo, setBaseInfo] = useState<BaseInfo>({ url: [], current: {} });

  const [meta, setMeta] = useState<Meta>({ title: '', description: '', toc: false, bread: false });

  // URL
  useEffect(() => {
    const arr = globalHistory.location.pathname.split('/');

    arr.splice(
      arr.findIndex(elem => elem === ''),
      1,
    );

    setBaseInfo({ ...baseInfo, url: arr });
  }, []);

  // CURRENT
  useEffect(() => {
    if (baseInfo.url[0] === 'blog' && baseInfo.url.length === 1) {
      return setBaseInfo({
        ...baseInfo,
        current: {
          SEO: {
            Breadcrumbs: true,
            Description: 'Блог Кати Медушкиной',
            TableOfContent: true,
            Title: 'Блог',
          },
        },
      });
    }
    if (pages && baseInfo.url[0] === '') {
      return setBaseInfo({ ...baseInfo, current: pages.find(elem => elem.name === 'about') });
    }
    if (blogCategories && baseInfo.url[0] === 'blog' && baseInfo.url.length === 2) {
      return setBaseInfo({ ...baseInfo, current: blogCategories.find(elem => elem.routeName === baseInfo.url[1]) });
    }
    if (articles && baseInfo.url[0] === 'blog' && baseInfo.url.length === 3) {
      return setBaseInfo({ ...baseInfo, current: articles.find(elem => elem.routeName === baseInfo.url[2]) });
    }
    if (pages && baseInfo.url.length === 1) {
      return setBaseInfo({ ...baseInfo, current: pages.find(elem => elem.name === baseInfo.url[0]) });
    }
    if (projects && baseInfo.url[0] === 'project' && baseInfo.url.length === 2) {
      return setBaseInfo({ ...baseInfo, current: projects.find(elem => elem.Name === baseInfo.url[1]) });
    }
    if (products && baseInfo.url[0] === 'product' && baseInfo.url.length === 2) {
      return setBaseInfo({ ...baseInfo, current: products.find(elem => elem.Name === baseInfo.url[1]) });
    }
  }, [pages, projects, politics, blogCategories, articles, baseInfo.url]);

  // META, TOC AND BREAD
  const checkTitle = () => {
    return document.querySelector('h1')?.innerText || process.env.DEFAULT_SEO_TITLE;
  };

  useEffect(() => {
    if (baseInfo.current && baseInfo.current.SEO && baseInfo.current.SEO.length !== 0) {
      const { SEO } = baseInfo.current;

      return setMeta({
        title: SEO.Title !== '' ? SEO.Title : checkTitle(),
        description: SEO.Description,
        toc: SEO.TableOfContent,
        bread: SEO.Breadcrumbs,
      });
    }
    return setMeta({
      ...meta,
      title: checkTitle(),
    });
  }, [baseInfo.current]);

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <meta charSet="utf-8"/>
          <title>{meta.title}</title>
          <meta name="description" content={meta.description} data-react-helmet="true"/>
          <script src={withPrefix('ws.js')} type="text/javascript"/>
          <script src="https://cdn.onesignal.com/sdks/OneSignalSDK.js" async=""></script>
          <script>{`
              window.OneSignal = window.OneSignal || [];
              OneSignal.push(function() {
              OneSignal.init({
              appId: 'ae8e0a5e-ec8b-4f38-8eb3-856fcd9ec208',
            });
            });
          `}
          </script>
          <script>
            {`
                      !function(f,b,e,v,n,t,s)
                      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                        n.queue=[];t=b.createElement(e);t.async=!0;
                        t.src=v;s=b.getElementsByTagName(e)[0];
                        s.parentNode.insertBefore(t,s)}(window, document,'script',
                      'https://connect.facebook.net/en_US/fbevents.js');
                      fbq('init', '300076172953213');
                      fbq('track', 'PageView');
                      `}
          </script>
          <script type="text/javascript">
            {`
              (function(d, w, m) {
                window.supportAPIMethod = m;
                var s = d.createElement('script');
                s.type = 'text/javascript';
                s.id = 'supportScript';
                s.charset = 'utf-8';
                s.async = true;
                var id = 'dac4d52c96f2ef83d8304f14b5eb0de1';
                s.src = 'https://lcab.talk-me.ru/support/support.js?h=' + id;
                var sc = d.getElementsByTagName('script')[0];
                w[m] = w[m] || function() {
                  (w[m].q = w[m].q || []).push(arguments);
                };
                if (sc) sc.parentNode.insertBefore(s, sc);
                else d.documentElement.firstChild.appendChild(s);
              })(document, window, 'TalkMe');
            `}
          </script>
          <noscript>{`
            <img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=300076172953213&ev=PageView&noscript=1"/>
          `}
          </noscript>
          {article && (
              <script type="application/ld+json">
                {`{
              "@context": "https://schema.org",
              "@type": "NewsArticle",
              "mainEntityOfPage": {
                "@type": "WebPage",
                "@id": "https://google.com/article"
              },
              "headline": "${article?.title}",
              "image": [
               "https://admin.goslim.pro${article?.picture?.url}"
              ],
              "datePublished": "${article?.published_at}",
              "dateModified": "${article?.updated_at}",
              "author": {
                "@type": "Person",
                "name": "Katya Medushkina"
              },
              "publisher": {
                "@type": "Organization",
                "name": "GoSlim"
              }
            }`}
              </script>
          )}

          <link rel="stylesheet" href="https://storage.googleapis.com/image-bucket-stage/s/cookies-consent.min.css"/>
          <script async src="https://cdn.skillzup.pro/s/ссd.min.js?id=R1RNLTU0SktaVzg="></script>
        </Helmet>
      </HelmetProvider>
      {meta.toc ? <Toc/> : false}
      {meta.bread ? <Bread urlArr={baseInfo.url} seo={baseInfo.current.SEO}/> : false}
    </>
  );
};

export default SeoModule;
