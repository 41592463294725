import * as React from 'react';
function SvgInstagram(props) {
  return (
    <svg width={29} height={29} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M17.113 14.602a2.75 2.75 0 11-5.498 0 2.75 2.75 0 015.498 0z" fill="#A4CE41" />
      <path d="M17.113 14.602a2.75 2.75 0 11-5.498 0 2.75 2.75 0 015.498 0z" fill="url(#Instagram_svg__paint0_linear)" />
      <path
        d="M20.794 9.738a2.556 2.556 0 00-.617-.949 2.554 2.554 0 00-.949-.617c-.29-.112-.727-.247-1.531-.283-.87-.04-1.13-.049-3.333-.049-2.203 0-2.463.009-3.333.048-.804.037-1.241.172-1.531.284a2.552 2.552 0 00-.949.617c-.274.266-.485.59-.617.949-.113.29-.247.727-.284 1.531-.04.87-.048 1.13-.048 3.333 0 2.203.008 2.463.048 3.333.037.805.171 1.241.284 1.532.132.358.343.682.617.948.266.274.59.485.948.617.291.113.728.247 1.532.284.87.04 1.13.048 3.333.048 2.202 0 2.463-.008 3.333-.048.804-.037 1.24-.17 1.531-.284a2.731 2.731 0 001.566-1.565c.113-.29.247-.727.284-1.532.04-.87.048-1.13.048-3.333 0-2.202-.009-2.463-.048-3.333-.037-.804-.171-1.24-.284-1.531zm-6.43 9.1a4.235 4.235 0 110-8.471 4.235 4.235 0 010 8.47zm4.403-7.649a.99.99 0 110-1.98.99.99 0 010 1.98z"
        fill="#A4CE41"
      />
      <path
        d="M20.794 9.738a2.556 2.556 0 00-.617-.949 2.554 2.554 0 00-.949-.617c-.29-.112-.727-.247-1.531-.283-.87-.04-1.13-.049-3.333-.049-2.203 0-2.463.009-3.333.048-.804.037-1.241.172-1.531.284a2.552 2.552 0 00-.949.617c-.274.266-.485.59-.617.949-.113.29-.247.727-.284 1.531-.04.87-.048 1.13-.048 3.333 0 2.203.008 2.463.048 3.333.037.805.171 1.241.284 1.532.132.358.343.682.617.948.266.274.59.485.948.617.291.113.728.247 1.532.284.87.04 1.13.048 3.333.048 2.202 0 2.463-.008 3.333-.048.804-.037 1.24-.17 1.531-.284a2.731 2.731 0 001.566-1.565c.113-.29.247-.727.284-1.532.04-.87.048-1.13.048-3.333 0-2.202-.009-2.463-.048-3.333-.037-.804-.171-1.24-.284-1.531zm-6.43 9.1a4.235 4.235 0 110-8.471 4.235 4.235 0 010 8.47zm4.403-7.649a.99.99 0 110-1.98.99.99 0 010 1.98z"
        fill="url(#Instagram_svg__paint1_linear)"
      />
      <path
        d="M14.364.238C6.432.238 0 6.67 0 14.602s6.432 14.364 14.364 14.364 14.364-6.432 14.364-14.364S22.296.238 14.364.238zm8.198 17.765c-.04.878-.18 1.477-.383 2.002a4.217 4.217 0 01-2.412 2.412c-.525.204-1.125.343-2.002.383-.88.04-1.16.05-3.4.05-2.241 0-2.522-.01-3.402-.05-.877-.04-1.477-.179-2.002-.383a4.04 4.04 0 01-1.46-.951 4.042 4.042 0 01-.952-1.461c-.204-.525-.343-1.124-.383-2.002-.04-.88-.05-1.16-.05-3.4s.01-2.522.05-3.401c.04-.878.179-1.478.383-2.002A4.04 4.04 0 017.5 7.739a4.04 4.04 0 011.461-.952c.525-.204 1.124-.343 2.002-.383.88-.04 1.16-.05 3.4-.05s2.522.01 3.401.05c.878.04 1.478.18 2.003.383.55.207 1.049.532 1.46.952.42.412.745.91.952 1.46.204.525.343 1.125.383 2.003.04.88.05 1.16.05 3.4s-.01 2.521-.05 3.4z"
        fill="#A4CE41"
      />
      <path
        d="M14.364.238C6.432.238 0 6.67 0 14.602s6.432 14.364 14.364 14.364 14.364-6.432 14.364-14.364S22.296.238 14.364.238zm8.198 17.765c-.04.878-.18 1.477-.383 2.002a4.217 4.217 0 01-2.412 2.412c-.525.204-1.125.343-2.002.383-.88.04-1.16.05-3.4.05-2.241 0-2.522-.01-3.402-.05-.877-.04-1.477-.179-2.002-.383a4.04 4.04 0 01-1.46-.951 4.042 4.042 0 01-.952-1.461c-.204-.525-.343-1.124-.383-2.002-.04-.88-.05-1.16-.05-3.4s.01-2.522.05-3.401c.04-.878.179-1.478.383-2.002A4.04 4.04 0 017.5 7.739a4.04 4.04 0 011.461-.952c.525-.204 1.124-.343 2.002-.383.88-.04 1.16-.05 3.4-.05s2.522.01 3.401.05c.878.04 1.478.18 2.003.383.55.207 1.049.532 1.46.952.42.412.745.91.952 1.46.204.525.343 1.125.383 2.003.04.88.05 1.16.05 3.4s-.01 2.521-.05 3.4z"
        fill="url(#Instagram_svg__paint2_linear)"
      />
      <defs>
        <linearGradient id="Instagram_svg__paint0_linear" x1={14.405} y1={11.852} x2={14.405} y2={17.351} gradientUnits="userSpaceOnUse">
          <stop stopColor="#D3E321" />
          <stop offset={1} stopColor="#fff" stopOpacity={0} />
        </linearGradient>
        <linearGradient id="Instagram_svg__paint1_linear" x1={14.465} y1={7.84} x2={14.465} y2={21.364} gradientUnits="userSpaceOnUse">
          <stop stopColor="#D3E321" />
          <stop offset={1} stopColor="#fff" stopOpacity={0} />
        </linearGradient>
        <linearGradient id="Instagram_svg__paint2_linear" x1={14.579} y1={0.238} x2={14.579} y2={28.966} gradientUnits="userSpaceOnUse">
          <stop stopColor="#D3E321" />
          <stop offset={1} stopColor="#fff" stopOpacity={0} />
        </linearGradient>
      </defs>
    </svg>
  );
}
export default SvgInstagram;
