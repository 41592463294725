import { WindowLocation } from '@reach/router';
import React, { useEffect, useRef } from 'react';

export interface ScrollToTopProps {
  location?: WindowLocation;
  value?: number;
}

const ScrollToTop: React.FC<ScrollToTopProps> = ({ location, value }) => {
  const scrollRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    scrollRef.current!.scrollIntoView(false);
  }, [location, value]);
  return <div ref={scrollRef} />;
};

export default ScrollToTop;
