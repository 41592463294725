import * as React from 'react';
function SvgAuthIcon(props) {
  return (
    <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M18.185.013C18.165.01 18.147 0 18.125 0H9.167a2.503 2.503 0 00-2.5 2.5v.833a.834.834 0 001.666 0V2.5c0-.46.374-.833.834-.833h3.882l-.254.085a1.675 1.675 0 00-1.128 1.581v12.5h-2.5A.835.835 0 018.333 15v-1.667a.834.834 0 00-1.666 0V15c0 1.378 1.121 2.5 2.5 2.5h2.5v.833c0 .92.747 1.667 1.666 1.667.179 0 .348-.026.531-.083l5.007-1.669A1.675 1.675 0 0020 16.667v-15A1.67 1.67 0 0018.185.013z"
        fill="#B5DC69"
      />
      <path
        d="M8.922 7.744L5.59 4.411A.833.833 0 004.167 5v2.5H.833a.834.834 0 000 1.667h3.334v2.5a.835.835 0 001.422.589l3.333-3.334a.832.832 0 000-1.178z"
        fill="#B5DC69"
      />
    </svg>
  );
}
export default SvgAuthIcon;
