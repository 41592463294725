export enum StepStatus {
  wait = 'wait',
  process = 'process',
  finish = 'finish',
  error = 'error',
}

export enum ProductCartType {
  default = 'default',
  description = 'description',
}

export enum NavigationPath {
  slimmingSchool = '/health-and-nutrition',
  about = '/',
  academyOfNutrition = '/academy-of-nutrition',
  onlineProfessionAcademy = '/online-profession-academy',
  faceFitness = '/face-fitness',
  fitnessEquipment = '/fitness-equipment',
  fitnessTour = '/fitness-tour',
  promotionAcademy = '/academy-of-promotion',
  reviews = '/reviews',
  blog = '/blog',
  FAQ = '/faq',
  contacts = '/contacts',
  partners = '/partners',
  terms = '/terms',
  privacyPolicy = '/privacy-policy',
  cookiePolicy = '/cookie-policy',
}

export enum NavigationName {
  slimmingSchool = 'slimmingSchool',
  about = 'about',
  academyOfNutrition = 'academyOfNutrition',
  onlineProfessionAcademy = 'onlineProfessionAcademy',
  faceFitness = 'faceFitness',
  fitnessEquipment = 'fitnessEquipment',
  fitnessTour = 'fitnessTour',
  promotionAcademy = 'promotionAcademy',
  reviews = 'reviews',
  blog = 'blog',
  FAQ = 'FAQ',
  contacts = 'contacts',
  partners = 'partners',
  terms = 'terms',
  privacyPolicy = 'privacyPolicy',
  cookiePolicy = 'cookiePolicy',
}

export enum LogoType {
  black = 'black',
  white = 'white',
}

export enum Langs {
  en = 'en',
  ru = 'ru',
}

export enum ButtonType {
  present = 'present',
  centimeter = 'centimeter',
}

export enum IconsType {
  instagram = 'instagram',
  youtube = 'youtube',
  telegram = 'telegram',
  facebook = 'facebook',
  vkontakte = 'vkontakte',
  whatsapp = 'whatsapp',
  mail = 'mail',
  visa = 'visa',
  paypal = 'paypal',
  mastercard = 'mastercard',
  stripe = 'stripe',
  cash = 'cash',
  user = 'user',
  store = 'strore',
}

export enum MarkedElementIcons {
  market = 'market',
  vector = 'vector',
  auth = 'auth',
  mail = 'mail',
}

export enum CardItemType {
  direction = 'direction',
  post = 'post',
  description = 'description',
  awards = 'awards',
  featured = 'featured',
  review = 'review',
  taplinkVideo = 'taplinkVideo',
}

export enum CircleBgType {
  promo = 'promo',
  author = 'author',
  list = 'list',
  about = 'about',
  partners = 'partners',
  contact = 'contact',
  fitnessEquipment = 'fitnessEquipment',
}

export enum FontWeight {
  black = 900,
  bold = 800,
  medium = 500,
  normal = 400,
  light = 300,
  hairline = 100,
}

export enum SmallItemType {
  default = 'default',
  column = 'column',
  columnSmallItem = 'columnSmallItem',
}

export enum PictureWithTextType {
  default = 'default',
  withoutContainer = 'withoutContainer',
  descriptionBlock = 'descriptionBlock',
  blockWithCircle = 'blockWithCircle',
  blockWithCircleReview = 'blockWithCircleReview',
  partners = 'partners',
}

export enum SliderBlocksType {
  achievements = 'achievements',
  reviews = 'reviews',
  reviewImage = 'reviewImage',
  taplinkDefault = 'taplinkDefault',
  taplinkReviews = 'taplinkReviews',
  taplinkVideo = 'taplinkVideo',
  beforeAfter = 'beforeAfter',
}

export enum PromoBlockType {
  default = 'default',
  withoutCircle = 'withoutCircle',
  fitnessEquipment = 'fitnessEquipment',
  about = 'about',
  partners = 'partners',
  project = 'project',
}

export enum CartItemsType {
  default = 'default',
  itemsOnLine = 'itemsOnLine',
}

export enum pathName {
  fitnessEquipment = 'fitnessEquipment',
  delivery = 'delivery',
  refund = 'refund',
}
