import { navigate } from 'gatsby';
import React, { createContext, useEffect, useState } from 'react';
import { getCurrentLangKey, getLangs, getUrlForLang } from 'ptz-i18n';
import { useTranslation } from 'react-i18next';
import { useLocation } from '@reach/router';
import queryString from 'query-string';

export type LangKey = 'en' | 'ru';

export interface Languages {
  languages: LangKey[];
  defaultLangKey: LangKey;
}

export const LangContext = createContext({});

export interface LangProviderProps {
  langs: Languages;
  pathname: string;
  children: React.ReactNode;
}

export interface LangConsumerProps {
  defaultLangKey?: string;
  langKey?: string;
  langsList?: {
    langKey: string;
    link: string;
    selected: boolean;
  }[];
  pathname?: string;
  toggleLanguage?: (value: string) => void;
}

export const LangProvider: React.SFC<LangProviderProps> = props => {
  const location = useLocation();
  const { i18n } = useTranslation();
  const { langs, pathname, children } = props;
  const { languages, defaultLangKey } = langs;
  const currentLangKey = getCurrentLangKey(languages, defaultLangKey, pathname);
  const langsList = getLangs(languages, currentLangKey, getUrlForLang(`/${currentLangKey}/`, pathname));
  const [langKey, setLang] = useState(currentLangKey);

  useEffect(() => {
    const { lang: urlParamLang } = queryString.parse(location.search);
    const localLang = localStorage.getItem('lang');

    // TODO uncomment navigator.language part when main site has an english content
    let lang = urlParamLang || localLang || navigator.language.split('-')[0];
    const isSupportedLang = languages.find(l => lang === l);
    if (!isSupportedLang) lang = defaultLangKey;
    setLang(lang);
    localStorage.setItem('lang', lang);
    i18n.changeLanguage(lang);
  }, []);

  const onChangeLang = (lang: string) => {
    const selected = langsList.filter(item => item.langKey === langKey)[0].langKey;
    const selectedLang = langsList.find(item => item.langKey === lang) || langsList[0];
    const prefix = selectedLang.langKey === defaultLangKey ? '/' : selectedLang.link;
    const splitKey = selected === defaultLangKey ? '/' : `/${selected}/`;
    const pathArray = pathname.split(splitKey);
    let to = '';

    if (pathArray[1]) [, to] = pathArray;
    else if (pathArray[0] !== '/') [to] = pathArray;
    i18n.changeLanguage(lang);
    localStorage.setItem('lang', lang);
    setLang(lang);
    navigate(`${prefix}${to}`);
  };

  return (
    <LangContext.Provider
      value={{
        defaultLangKey,
        langKey,
        langsList,
        pathname,
        toggleLanguage: (value: string) => onChangeLang(value),
      }}
    >
      {children}
    </LangContext.Provider>
  );
};

export const LangConsumer: React.SFC<{ children: (value: {}) => React.ReactNode }> = ({ children }) => (
  <LangContext.Consumer>{children}</LangContext.Consumer>
);
