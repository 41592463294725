import React from 'react';
import { ButtonWrapper, StyledButton, Present, Centimeter } from './style';
import style from './style.module.scss';
import { ButtonType, FontWeight, IconsType } from '../../lib/constants/enums';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import cx from 'classnames';
import { colors } from '../../styles/variables';
import telegram from '../../assets/icons/telegram-1.svg';
import whatsapp from '../../assets/icons/whatsapp-1.svg';

interface SocialButtonProps {
  title: string;
  border?: boolean;
  width?: number;
  type?: ButtonType;
  fontWeight?: FontWeight;
  uppercase?: boolean;
  fontSize?: number;
  fontColor?: string;
  onClick?: () => void;
  loading?: boolean;
  css?: React.CSSProperties;
  className?: string;
  bgColor?: string;
  shadowColor?: string;
  active?: boolean;
  from?: string;
  to?: string;
  icon?: IconsType;
}

const SocialButton: React.FC<SocialButtonProps> = props => {
  const {
    title,
    border,
    width,
    type,
    fontWeight = FontWeight.normal,
    fontSize,
    uppercase,
    onClick,
    loading,
    css,
    className,
    bgColor,
    shadowColor,
    active = false,
    from = colors.yellow,
    to = colors.green,
    fontColor = colors.btngray,
    icon,
  } = props;
  const viewImage = type === ButtonType.present ? <Present /> : <Centimeter />;
  const antIcon = <LoadingOutlined className={style.buttonSpin} spin />;
  return (
    <ButtonWrapper className={style.buttonWrap} width={width} border={border} active={active} bgColor={bgColor}>
      <StyledButton
        disabled={loading}
        style={css}
        onClick={onClick}
        className={cx(style.button, className)}
        fontWeight={fontWeight}
        fontColor={fontColor}
        uppercase={uppercase}
        fontSize={fontSize}
        active={active}
        bgColor={bgColor}
        shadowColor={shadowColor}
      >
        <div className={style.buttonInside}>
          {!loading && icon === IconsType.telegram && (
            <div className={style.iconWrap}>
              <img src={telegram} alt="icon" />
            </div>
          )}
          {!loading && icon === IconsType.whatsapp && (
            <div className={style.iconWrap}>
              <img src={whatsapp} alt="icon" />
            </div>
          )}
          {loading && <Spin indicator={antIcon} />}
          {title}
          {type ? viewImage : null}
        </div>
      </StyledButton>
    </ButtonWrapper>
  );
};

export default SocialButton;
