import React from 'react';
import { Row } from 'antd';
import Container from '../../../components/Layouts/Container/Container';
import { ProductItem } from './ProductItem/ProductItem';
import { PayformProductResponseDto } from '../../../graphql-types';

interface ProductsProps {
  items: PayformProductResponseDto[];
}

const Products: React.FC<ProductsProps> = ({ items }) => {
  return (
    <Container style={{ maxWidth: '1180px', marginLeft: 'auto', marginRight: 'auto' }}>
      <Row type="flex">
        {items.map(product => (
          <React.Fragment key={product.id}>
            <ProductItem product={product} />
          </React.Fragment>
        ))}
      </Row>
    </Container>
  );
};

export default Products;
