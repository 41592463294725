import React from 'react';
import cx from 'classnames';
import style from './style.module.scss';

interface LayoutBGProps {
  children: React.ReactNode;
  background?: string | null;
  className?: string;
}

const LayoutBG: React.FC<LayoutBGProps> = props => {
  const { children, background, className } = props;
  return (
    <div style={{ background: background || undefined }} className={cx(style.layoutBG__wrapper, className)}>
      {children}
    </div>
  );
};

export default LayoutBG;
