import React, { useEffect } from 'react';
import { Link, navigate } from 'gatsby';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, Typography, Divider } from 'antd';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/react-hooks';
import { InvoiceCreateRequestDto } from '@altermeliora/payform-types';
import { ApolloError } from 'apollo-boost';
import { ClearCartActionCreator } from '../../../redux/cart/cart.actions';
import { SubmitOrderActionCreator } from '../../../redux/order/order.actions';
import { itemDiscountTotal, toDecimals, pathTo, CurrencySymbols, fixedNum } from '../../../lib/utils';
import { useRates } from '../../../lib/hooks/rates';
import { CurrencySelector } from '../../../redux/currency/currency.selector';

import { OrderSelector } from '../../../redux/order/order.selector';
import { fields, dataSubfields } from '../../../lib/constants/data';
import { PayformCurrency } from '../../../lib/constants';
import { CREATE_INVOICE_REQUEST, INVOICE_PAY_REQUEST } from '../../../api/api-apollo/mutation/invoice';

import { LanguageType } from '../../../graphql-types';
import Container from '../../../components/Layouts/Container/Container';

import css from './checkoutconfirm.module.scss';

export interface CheckoutConfirmProps {
  visible: boolean;
  onCancel: () => void;
}

export const CheckoutConfirm = (props: CheckoutConfirmProps) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { visible, onCancel } = props;
  const { convert, rates } = useRates();
  const { currency } = useSelector(CurrencySelector);
  const { order } = useSelector(OrderSelector);
  const { Title, Text } = Typography;

  const [createInvoiceRequest, { data: InvoiceCreateData, loading: InvoiceLoadingStatus, error: InvoiceCreateError }] = useMutation(
    CREATE_INVOICE_REQUEST
  );
  const [invoicePayRequest, { data: InvoicePayData, loading: InvoicePayLoadingStatus, error: InvoicePayError }] = useMutation(
    INVOICE_PAY_REQUEST
  );

  const errorCallback = (error: ApolloError) => {
    navigate('/');
    // message.error(error.message);
  };

  useEffect(() => {
    if (InvoiceCreateError) {
      errorCallback(InvoiceCreateError);
      return;
    }
    if (InvoicePayError) {
      errorCallback(InvoicePayError);
    }
    if (InvoicePayData && InvoicePayData.invoicePay && order && !InvoicePayError) {
      dispatch(ClearCartActionCreator());
      dispatch(SubmitOrderActionCreator(order));
      window.location.replace(InvoicePayData.invoicePay.paymentUrl);
    }
  }, [InvoicePayData, InvoicePayError, InvoiceCreateError]);

  useEffect(() => {
    if (InvoiceCreateData && InvoiceCreateData.createInvoice && order) {
      const { paymentMethod, shippingMethod, cart } = order;

      const dto = {
        paymentProviderId: Number(paymentMethod?.id),
        currency,
        calculatedDeliveryMethod: {
          deliveryMethodId: Number(shippingMethod?.deliveryMethodId),
          price: shippingMethod?.price,
          currency: shippingMethod?.currency,
          products: cart.map(product => ({
            id: product.productId,
            quantity: product.quantity,
            packageId: shippingMethod?.packagesCount,
          })),
        },
      };

      invoicePayRequest({
        variables: {
          dto,
          uid: InvoiceCreateData.createInvoice.uid,
        },
      });
    }
  }, [InvoiceCreateData]);

  const onConfirm = () => {
    if (order) {
      const { participant, payer, cart } = order;
      const dataForNewInvoice: InvoiceCreateRequestDto = {
        participant,
        payer,
        cart,
      };

      createInvoiceRequest({
        variables: {
          dto: dataForNewInvoice,
        },
      });
    }
  };

  const renderFields = () => {
    return Object.entries(fields).map(([step, f]) =>
      f.map(field => (
        <Container className={css.fieldContainer} key={field}>
          <Text type="secondary" className={css.label}>
            {t(`checkout:form.${step}.fields.${field}.label`)}
          </Text>
          <Text strong>
            {(order && order.participant && order.participant[field]) ||
              (order && order[dataSubfields[step]] && order[dataSubfields[step]][field])}
          </Text>
        </Container>
      ))
    );
  };

  const renderCart = () => {
    if (order && order.products) {
      return order.products.map(cartitem => {
        const { amount, currency: productCurrency } = cartitem.product.prices[0].list![0];
        const priceConverted = convert(amount, productCurrency, currency);
        return (
          <Container className={css.itemContainer} key={cartitem.product.id as string}>
            <Container>
              {cartitem && cartitem.product.name && cartitem.product.photos.length ? (
                <img
                  src={cartitem.product.photos.length && cartitem.product.photos[0].path}
                  alt={cartitem.product.name[i18n.language as LanguageType]}
                />
              ) : null}
            </Container>
            <Container className={css.innerContainer}>
              <Container className={css.titleOptionContainer}>
                <Link to={pathTo(cartitem.product.slug as string)}>
                  <Typography.Text className={css.itemText}>{cartitem.product.name[i18n.language as LanguageType]}</Typography.Text>
                </Link>
                {cartitem.option && (
                  <Typography.Text className={css.optionText} type="secondary">{`${cartitem.option.value}`}</Typography.Text>
                )}
                {cartitem.colorOption && (
                  <Typography.Text className={css.optionText} type="secondary">
                    {`${t('shell:cart.color')}: ${cartitem.colorOption.name}`}
                    <div className={css.colorSwatch} style={{ backgroundColor: cartitem.colorOption.hexCode }} />
                  </Typography.Text>
                )}
              </Container>
              <Container className={css.priceContainer}>
                <Typography.Text className={css.itemText}>{`${CurrencySymbols[currency]} ${fixedNum(priceConverted)}`}</Typography.Text>
                &times;
                <Typography.Text className={css.itemText}>{cartitem.quantity}</Typography.Text>
                <Container className={css.itemTotal}>
                  <Typography.Text className={cartitem.discount ? css.price : undefined}>
                    {`${CurrencySymbols[currency]} ${fixedNum(priceConverted * cartitem.quantity)} `}
                  </Typography.Text>
                  {cartitem.discount && (
                    <Typography.Text className={css.discount}>- &euro;{itemDiscountTotal(cartitem) * cartitem.quantity}</Typography.Text>
                  )}
                  <Typography.Text>
                    = {`${CurrencySymbols[currency]} `}
                    {fixedNum(
                      cartitem.quantity * priceConverted - (cartitem.discount ? itemDiscountTotal(cartitem) * cartitem.quantity : 0)
                    )}
                  </Typography.Text>
                </Container>
              </Container>
              {cartitem.discount && <Typography.Text className={css.discountTitle}>{cartitem.discount.title}</Typography.Text>}
            </Container>
          </Container>
        );
      });
    }

    return null;
  };

  const evaluateSubTotal = (): number => {
    let total = 0;
    if (order && order.products && rates) {
      order.products.map(item => {
        const { amount, currency: productCurrency } = item.product.prices[0].list![0];
        const convertedPrice = convert(amount, productCurrency, currency);
        total += convertedPrice * item.quantity;
      });
    }
    return total;
  };

  const evaluateShippingTotal = (): number => {
    let total = 0;
    if (order && order.shippingMethod && rates) {
      const convertedPrice = convert(order.shippingMethod.price, PayformCurrency.Euro, currency);
      total += convertedPrice;
    }
    return total;
  };

  const evaluateDiscountTotal = (): number => {
    let total = 0;
    if (order && order.products) {
      order.products.map(item => {
        total += itemDiscountTotal(item, currency) * item.quantity;
      });
    }
    return total;
  };

  const subTotal: number = toDecimals(evaluateSubTotal());
  const discount: number = toDecimals(evaluateDiscountTotal());
  const shipping: number = toDecimals(evaluateShippingTotal());
  const total: number = toDecimals(subTotal - discount + shipping);

  return (
    <>
      {order && (
        <Modal
          visible={visible}
          onCancel={() => onCancel()}
          onOk={() => onConfirm()}
          confirmLoading={InvoiceLoadingStatus || InvoicePayLoadingStatus}
          okText={t('checkout:confirmModal.okBtn.label')}
          cancelText={t('checkout:confirmModal.cancelBtn.label')}
        >
          <Title level={4}>{t('checkout:confirmModal.title')}</Title>
          <Container className={css.dataContainer}>
            {renderCart()}
            <Divider />
            {renderFields()}
            <Divider />
            {discount > 0 && (
              <Container className={css.totalsContainer}>
                <Text type="secondary" className={css.orderDiscount}>
                  {t('checkout:confirmModal.discount')}
                </Text>
                <Text type="secondary" className={css.orderDiscount}>
                  {`${CurrencySymbols[currency]} ${discount}`}
                </Text>
              </Container>
            )}
            <Container className={css.totalsContainer}>
              <Text className={css.orderTotal}>{t('checkout:confirmModal.orderTotal')}</Text>
              <Text className={css.orderTotal}>{`${CurrencySymbols[currency]} ${total}`}</Text>
            </Container>
          </Container>
        </Modal>
      )}
    </>
  );
};
