import React, { ReactNode } from 'react';
import style from './style.module.scss';
import { MarkedItemIcons } from './data';
import { MarkedElementIcons } from '../../lib/constants/enums';
import classnames from 'classnames';

type alignItems = 'center' | 'flex-start' | 'flex-end';
type iconPosition = 'left' | 'right' | 'top' | 'bottom';

interface MarkedElementProps {
  children: ReactNode;
  icon?: MarkedElementIcons;
  className?: string;
  align?: alignItems;
  justify?: string;
  direction?: string;
  width?: number;
  height?: number;
  defaultIconColor?: string;
  iconPosition?: iconPosition;
}

const MarkedElement: React.FC<MarkedElementProps> = props => {
  const { children, icon, className, align, justify, direction, width, height, defaultIconColor, iconPosition = 'left' } = props;
  const defaultIcon = (
    <div
      className={style.icon}
      style={{
        background: defaultIconColor ? defaultIconColor : '#a2ce2c',
        margin: iconPosition === 'top' || iconPosition === 'bottom' ? '0 auto' : '0',
      }}
    />
  );
  const Icon = icon && MarkedItemIcons[icon];
  return (
    <div
      style={{ alignItems: align ? align : 'center' }}
      className={classnames(style.markedElement, {
        [style.widthIconPositionVertical]: iconPosition === 'top' || iconPosition === 'bottom',
      })}
    >
      {iconPosition === 'left' || iconPosition === 'top' ? (
        <>
          <div className={style.markedElementIconWrapper}>{Icon ? <Icon /> : defaultIcon}</div>
          <div className={style.content}>{children}</div>
        </>
      ) : (
        <>
          <div className={style.content}>{children}</div>
          <div className={style.markedElementIconWrapper}>{Icon ? <Icon /> : defaultIcon}</div>
        </>
      )}
    </div>
  );
};

export default MarkedElement;
