import * as React from 'react';
function SvgTelegram(props) {
  return (
    <svg width={30} height={29} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M15.496 29c8.01 0 14.5-6.49 14.5-14.5S23.506 0 15.496 0 .996 6.49.996 14.5 7.486 29 15.496 29zM7.631 14.186l13.98-5.39c.65-.235 1.216.158 1.006 1.139l.001-.001-2.38 11.214c-.177.795-.65.989-1.31.614l-3.625-2.672-1.749 1.685c-.193.193-.356.356-.73.356l.257-3.689 6.718-6.07c.292-.256-.065-.402-.45-.145l-8.303 5.227-3.58-1.117c-.776-.246-.793-.777.165-1.151z"
        fill="#A4CE41"
      />
      <path
        d="M15.496 29c8.01 0 14.5-6.49 14.5-14.5S23.506 0 15.496 0 .996 6.49.996 14.5 7.486 29 15.496 29zM7.631 14.186l13.98-5.39c.65-.235 1.216.158 1.006 1.139l.001-.001-2.38 11.214c-.177.795-.65.989-1.31.614l-3.625-2.672-1.749 1.685c-.193.193-.356.356-.73.356l.257-3.689 6.718-6.07c.292-.256-.065-.402-.45-.145l-8.303 5.227-3.58-1.117c-.776-.246-.793-.777.165-1.151z"
        fill="url(#Telegram_svg__paint0_linear)"
      />
      <defs>
        <linearGradient id="Telegram_svg__paint0_linear" x1={15.713} y1={0} x2={15.713} y2={29} gradientUnits="userSpaceOnUse">
          <stop stopColor="#D3E321" />
          <stop offset={1} stopColor="#fff" stopOpacity={0} />
        </linearGradient>
      </defs>
    </svg>
  );
}
export default SvgTelegram;
