import React, { useState, useRef, useEffect } from 'react';
import style from './style.module.scss';
import { AdaptiveLayout } from '../../Layouts/Layouts';

import DotsMenu from './DotsMenu/DotsMenu';

interface SiteNavigationprops {
  children: JSX.Element[];
}

const SiteNavigation: React.FC<SiteNavigationprops> = ({ children }) => {
  const [elementWidths, setElementWidths] = useState<Array<number> | null>(null);
  const [menuContainerWidth, setMenuContainerWidth] = useState<number | null>(null);
  const ref = useRef<HTMLDivElement | null>(null);

  const getMenuContainerWidth = () => {
    if (ref.current) {
      const width = ref?.current.offsetWidth;
      const dotsMenuWidth = 15;
      return width - dotsMenuWidth;
    }
    return null;
  };

  const resize = () => {
    setMenuContainerWidth(() => getMenuContainerWidth());
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', resize, false);
      const domNode = ref.current;
      if (!elementWidths && domNode) {
        const menuContainerWidth = getMenuContainerWidth();
        const arrayChildren = Array.prototype.map.call(domNode?.children, function(childDomNode) {
          const ew = childDomNode.offsetWidth;
          return ew;
        });
        setMenuContainerWidth(() => menuContainerWidth);
        setElementWidths(() => arrayChildren);
      }
    }
    return () => {
      if (typeof window !== 'undefined') window.removeEventListener('resize', resize, false);
    };
  }, []);

  const renderItems = () => {
    let menuItems: JSX.Element[] = [];
    // menu items to be collapsed on the right
    let dotsMenuItems: JSX.Element[] = [];

    if (elementWidths && menuContainerWidth) {
      // accumulated widths of menu items displayed on the left
      let accWidth = 0;
      const indent = 200;

      // identify all items that may fit
      React.Children.map(children, function(element, index) {
        // if it's not a string
        if (React.isValidElement(element)) {
          const copy = React.cloneElement(element, {
            key: '__' + index,
          });

          if ((accWidth += elementWidths[index]) <= menuContainerWidth - indent) {
            // if elements still fit
            menuItems.push(copy);
          } else {
            // otherwise next under kebab menu
            dotsMenuItems.push(copy);
          }
        }
      });
    } else {
      menuItems = children;
    }

    return (
      <div className={style.navWrap}>
        <nav className={style.header__nav} ref={ref}>
          {menuItems}
        </nav>
        <DotsMenu>{dotsMenuItems}</DotsMenu>
      </div>
    );
  };

  return (
    <>
      <AdaptiveLayout onDesktop onTabletLarge>
        {renderItems()}
      </AdaptiveLayout>
      <AdaptiveLayout onTabletMedium onMobile>
        <div className={style.header__nav}>{children}</div>
      </AdaptiveLayout>
    </>
  );
};

export default SiteNavigation;
