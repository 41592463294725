import React from 'react';
import style from './style.module.scss';
import { Image } from 'antd';
import cx from 'classnames';

const productImageStub = require('../../assets/icons/product-image-stub.svg');

interface ImageViewerProps {
  image: string;
  alt?: string;
  className?: string;
}

const ImageViewer: React.FC<ImageViewerProps> = ({ image, alt, className }) => {
  return <Image src={image} className={cx(style.cardImage, className)} alt={alt || ''} fallback={productImageStub} />;
};

export default ImageViewer;
