import React from 'react';
import style from './style.module.scss';
import { Typography } from 'antd';
import { Button } from '../../../components';
import { useTranslation } from 'react-i18next';
import { MarkedElementIcons, FontWeight } from '../../../../lib/constants/enums';

import MarkedElement from '../../../MarkedElement/MarkedElement';

const Contact: React.FC = () => {
  const { Text, Link } = Typography;
  const { t } = useTranslation();
  return (
    <div className={style.contact}>
      <Text className={style.contactText}>
        <Text strong>{t(`footer:contact.question`)}</Text> {t(`footer:contact.description`)}
      </Text>
      <div className={style.mail}>
        <MarkedElement icon={MarkedElementIcons.mail}>
          <Link className={style.mailLink} href={`mailto:${t(`footer:contact.mail`)}`}>
            {t(`footer:contact.mail`)}
          </Link>
        </MarkedElement>
      </div>
      <a href="https://api.whatsapp.com/send?phone=447444065663&text=%D0%9A%D0%B0%D1%82%D1%8F,%20%D0%BF%D1%80%D0%B8%D0%B2%D0%B5%D1%82!%20%D0%A3%20%D0%BC%D0%B5%D0%BD%D1%8F%20%D0%B5%D1%81%D1%82%D1%8C%20%D0%B2%D0%BE%D0%BF%D1%80%D0%BE%D1%81" target="_blank">
        <Button border title={t(`footer:contact.buttonTitle`)} fontWeight={FontWeight.bold} uppercase />
      </a>
    </div>
  );
};

export default Contact;
