import React from 'react';
import style from './style.module.scss';
import classNames from 'classnames';
import { FontWeight } from '../../../lib/constants/enums';
import { colors } from '../../../styles/variables';
import { DownloadBtn } from '../../../Icons';

interface TaplinkButtonProps {
  label: string;
  description?: string | undefined | null;
  isActive?: boolean;
  isReview?: boolean;
  withIcon?: boolean;
  fontWeight?: FontWeight;
  fontSize?: string;
  fontColor?: string;
  background?: string;
  isDownloadType?: boolean;
  boxShadow?: string;
}

const TaplinkButton: React.FC<TaplinkButtonProps> = ({
  label,
  isActive,
  isReview,
  withIcon,
  isDownloadType = false,
  description,
  fontSize,
  fontWeight = 600,
  fontColor = colors.black,
  background = colors.defaultGradientButton,
  boxShadow = colors.defaultTaplinkButtonShadow,
}) => {
  const btnStyle = {
    fontSize,
    fontWeight,
    color: fontColor,
    background,
    boxShadow,
  };
  return (
    <div className={style.buttonWrapper}>
      <button style={btnStyle} className={classNames(style.button, { [style.isActive]: isActive, [style.isReview]: isReview })}>
        {withIcon && <div className={style.icon} />}
        {isDownloadType && (
          <div className={style.downloadIcon}>
            <DownloadBtn />
          </div>
        )}
        <div className={style.btnInfo}>
          <div className={style.buttonText}>{label}</div>
          <div className={style.buttonDescription}>{description}</div>
        </div>
      </button>
      {!isActive && <div className={style.border} />}
    </div>
  );
};

export default TaplinkButton;
